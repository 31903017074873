import React, { useState, useMemo, useEffect } from 'react';
import { Dialog, DialogContent, DialogTitle, DialogDescription } from "./ui/dialog";
import { AlertCircle, CheckCircle } from 'lucide-react';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import './PlanDetailModal.css';
import { io } from 'socket.io-client';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const options = {
  cMapUrl: 'https://unpkg.com/pdfjs-dist@3.4.120/cmaps/',
  cMapPacked: true,
  standardFontDataUrl: 'https://unpkg.com/pdfjs-dist@3.4.120/standard_fonts/'
};

const getIsapreLogo = (isapreName) => {
  try {
    switch (isapreName.toLowerCase()) {
      case 'colmena':
        return require('../assets/Colmena.webp');
      case 'esencial':
        return require('../assets/Esencial.webp');
      case 'nueva masvida':
        return require('../assets/NuevaMasvida.webp');
      case 'vidatres':
        return require('../assets/Vidatres.webp');
      case 'banmedica':
        return require('../assets/Banmedica.webp');
      case 'consalud':
        return require('../assets/Consalud.webp');
      default:
        return '';
    }
  } catch (error) {
    console.error(`No se pudo cargar el logo para la isapre: ${isapreName}`, error);
    return '';
  }
};

const PlanDetailModal = ({ isOpen, onClose, planDetails, onSubmit, activeTab: initialActiveTab }) => {
  const [activeTab, setActiveTab] = useState(initialActiveTab || 'general');
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [formData, setFormData] = useState({
    name: '',
    rut: '',
    email: '',
    phone: '',
    Ejecutivo: '',
    precio_total_clp: planDetails?.precio_total_clp || 'No especificado',
    precio_minimo_clp: planDetails?.precio_minimo_clp || 'No especificado',
    cargas: planDetails?.cargas || 'No especificado',
    edad_cotizante1: planDetails?.edad_cotizante1 || 'No especificado',
    edad_cotizante2: planDetails?.edad_cotizante2 || 'No especificado',
    region: planDetails?.region || 'No especificado',
    tipo_de_plan: planDetails?.tipo_de_plan || 'No especificado',
    nota: planDetails?.nota || 'No especificado',
    codigo_plan: planDetails?.codigo_plan || 'No especificado'
  });
  const [pdfScale, setPdfScale] = useState(1.3);
  const [buttonText, setButtonText] = useState('Solicitar con ejecutivo');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [videoCallFormSubmitted, setVideoCallFormSubmitted] = useState(false);
  const [contractFormSubmitted, setContractFormSubmitted] = useState(false);
  const [requestFormSubmitted, setRequestFormSubmitted] = useState(false);
  const [socket, setSocket] = useState(null);
  const [isConnectButtonPulsing, setIsConnectButtonPulsing] = useState(false);
  const [isRequestButtonPulsing, setIsRequestButtonPulsing] = useState(false);
  const [ejecutivoActivo, setEjecutivoActivo] = useState('');
  const [isSmallScreen, setIsSmallScreen] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 1100);
    };
    
    window.addEventListener('resize', handleResize);
    handleResize();
    
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
  const newSocket = io('https://infoplan.cl:4000');
  setSocket(newSocket);

  newSocket.on('connect', () => {
    console.log('Conectado al servidor WebSocket');
    newSocket.emit('getejecutivoActivo');  // Solicita el ejecutivoActivo al conectar
  });

  newSocket.on('sendejecutivoActivo', (data) => {
    console.log('Log antes de guardar ejecutivoActivo:', data);
    setEjecutivoActivo(data);
    setFormData((prevState) => ({
      ...prevState,
      Ejecutivo: data
    }));

    console.log('Ejecutivo Activo recibido:', data);

    if (window.recibirejecutivoActivo) {
      console.log('Enviando ejecutivoActivo al index:', data);
      window.recibirejecutivoActivo(data);
    }
  });

  return () => {
    newSocket.close();
  };
}, []);

useEffect(() => {
    if (planDetails && planDetails.ejecutivoActivo) {
      setEjecutivoActivo(planDetails.ejecutivoActivo);
      setFormData((prevState) => ({
        ...prevState,
        Ejecutivo: planDetails.ejecutivoActivo
      }));
      console.log('ejecutivoActivo desde planDetails:', planDetails.ejecutivoActivo);
    }
  }, [planDetails]);

  useEffect(() => {
    setActiveTab(initialActiveTab || 'general');
  }, [initialActiveTab]);


  const parseCoverageData = (data) => {
    if (Array.isArray(data)) return data;
    if (typeof data === 'string') {
      return data.split('\n').map(line => {
        if (line.includes(':')) {
          const [prestador, cobertura] = line.split(':');
          return { prestador: prestador.trim(), cobertura: cobertura ? cobertura.trim() : 'Libre Elección' };
        } else {
          const parts = line.split(/\s+/);
          const cobertura = parts.shift();
          const prestador = parts.join(' ');
          return { prestador: prestador.trim(), cobertura: cobertura ? cobertura.trim() : 'Libre Elección' };
        }
      });
    }
    return [];
  };

  const coverageData = useMemo(() => ({
    hospitalaria: parseCoverageData(planDetails?.cobertura_hospitalaria),
    ambulatoria: parseCoverageData(planDetails?.cobertura_ambulatoria),
    urgencia: parseCoverageData(planDetails?.cobertura_urgencia)
  }), [planDetails]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name) {
      alert("Por favor, ingresa tu nombre.");
      return;
    }
    if (!formData.phone) {
      alert("Por favor, ingresa un número de teléfono.");
      return;
    }

    setIsSubmitting(true);

    try {
      const cargasValue = Array.isArray(planDetails?.cargas)
        ? planDetails.cargas.join(',')
        : planDetails?.cargas || 'No especificado';

      const preparedPlanDetails = {
        isapre: planDetails?.isapre || 'No especificada',
        plan: planDetails?.plan || 'No especificado',
        precio_total_clp: formData.precio_total_clp,
        precio_minimo_clp: formData.precio_minimo_clp,
        cargas: cargasValue,
        edad_cotizante1: formData.edad_cotizante1,
        edad_cotizante2: formData.edad_cotizante2,
        region: formData.region,
        tipo_de_plan: formData.tipo_de_plan,
        nota: formData.nota,
        codigo_plan: formData.codigo_plan
      };

      const requestBody = {
        ...formData,
        planDetails: preparedPlanDetails
      };

      const response = await fetch('https://infoplan.cl:4000/api/solicitudes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        setButtonText('Enviado');
        alert('Formulario enviado con éxito.');

        onSubmit(requestBody);

        if (activeTab === 'contract') {
          setContractFormSubmitted(true);
        } else if (activeTab === 'request') {
          setRequestFormSubmitted(true);
        }
      } else {
        alert('Error al enviar el formulario.');
      }
    } catch (error) {
      alert('Error al conectar con el servidor.');
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleVideoCallSubmit = async (e) => {
    e.preventDefault();

    if (!formData.name) {
      alert("Por favor, ingresa tu nombre.");
      return;
    }
    if (!formData.phone) {
      alert("Por favor, ingresa un número de teléfono.");
      return;
    }

    setIsSubmitting(true);

    try {
      const cargasValue = Array.isArray(planDetails?.cargas)
        ? planDetails.cargas.join(',')
        : planDetails?.cargas || 'No especificado';

      const preparedPlanDetails = {
        isapre: planDetails?.isapre || 'No especificada',
        plan: planDetails?.plan || 'No especificado',
        precio_total_clp: formData.precio_total_clp,
        precio_minimo_clp: formData.precio_minimo_clp,
        cargas: cargasValue,
        edad_cotizante1: formData.edad_cotizante1,
        edad_cotizante2: formData.edad_cotizante2,
        region: formData.region,
        tipo_de_plan: formData.tipo_de_plan,
        nota: formData.nota,
        codigo_plan: formData.codigo_plan
      };

      const requestBody = {
        ...formData,
        planDetails: preparedPlanDetails
      };

      const response = await fetch('https://infoplan.cl:4000/api/solicitudes', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        setVideoCallFormSubmitted(true);
        onSubmit(requestBody);

        if (socket) {
          socket.emit('nueva_solicitud', requestBody);
        }
      } else {
        alert('Error al enviar el formulario.');
      }
    } catch (error) {
      alert('Error al conectar con el servidor.');
      console.error('Error:', error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const handleConnectClick = () => {
  setIsConnectButtonPulsing(true);
  setTimeout(() => setIsConnectButtonPulsing(false), 300);

  console.log('Valor de ejecutivoActivo antes de conectar:', ejecutivoActivo);

  if (socket) {
    socket.emit('solicitud_videollamada', { video: true });
    socket.emit('conectarEjecutivo', { ejecutivoActivo });
    console.log('Ejecutivo Activo enviado:', ejecutivoActivo);

    if (window.recibirejecutivoActivo) {
      window.recibirejecutivoActivo(ejecutivoActivo);
    }
  } else {
    console.error('Error: Socket no está inicializado');
  }

  // Cerrar el modal
  onClose();
};

  const normalize = (str) => str.trim().toLowerCase();

 const getBenefitsData = (isapre) => {
  const defaultBenefits = [
    {
      title: 'Beneficio General',
      icon: require('../assets/general.webp'),
      description: ['Beneficio general para todos los planes']
    }
  ];
  const benefitsByIsapre = {
    'nueva masvida': [
      {
        title: 'Salud Plus',
        icon: require('../assets/ambulatoria.webp'),
        description: ['Atención inicial gratuita por emergencias médicas durante dos años, con traslado incluido.', 'Consultas médicas a través de telemedicina y entrega de medicamentos.']
      },
      {
        title: 'Kinesiología',
        icon: require('../assets/kinesiologia_1.webp'),
        description: ['Hasta 20 sesiones domiciliarias de kinesiología gratuitas, con orden médica y realizadas por profesionales calificados.']
      },
      {
        title: 'Beneficio Pharma 30',
        icon: require('../assets/farmacia.webp'),
        description: ['Descuento del 30% en medicamentos seleccionados de marcas nacionales e internacionales por un año.']
      },
      {
        title: 'Rescate Emergencia Riesgo Vital',
        icon: require('../assets/rescate.webp'),
        description: ['Servicio de rescate y traslado en caso de urgencias vitales, con dos eventos anuales gratuitos por beneficiario durante un año.']
      },
      {
        title: 'Bono Costo Cero',
        icon: require('../assets/costo0.webp'),
        description: ['Exámenes de laboratorio e imagenología sin costo en prestadores de salud de alto nivel.']
      },
      {
        title: 'Descuentos en Farmacia',
        icon: require('../assets/farmacia.webp'),
        description: ['Acceso a descuentos especiales en Farmacias Ahumada como afiliado a Isapre Nueva Masvida.']
      },
      {
        title: 'Salud Dental con Descuentos Permanentes',
        icon: require('../assets/ambulatoria.webp'),
        description: ['Disfruta de descuentos permanentes en salud dental con prestadores como Integramédica, Red Salud, Sigma Dental y Uno Salud.']
      },
      {
        title: 'Red de Ópticas',
        icon: require('../assets/optica.webp'),
        description: ['Beneficios en servicios ópticos y protección auditiva en la red de ópticas afiliadas a nivel nacional.']
      },
      {
        title: 'Orientación Médica Telefónica',
        icon: require('../assets/telemedicina.webp'),
        description: ['Consulta médica telefónica gratuita disponible los 365 días del año para resolver tus inquietudes de salud.']
      }
    ],
    'consalud': [
      {
        title: 'Beneficios COVID-19',
        icon: require('../assets/hospitalaria.webp'),
        description: ['Financia hasta el 50% del plan de salud complementario y GES en caso de cesantía.', 'Cobertura completa en hospitalización por COVID-19, copago $0 en caso de cesantía.', 'Examen PCR y test rápido para COVID-19 sin costo en cualquier prestador nacional.']
      },
      {
        title: 'Bonos Sin Costo',
        icon: require('../assets/costo0.webp'),
        description: ['Bonificación del 100% en prestaciones de salud en centros como Bionet, Sonorad y más.']
      },
      {
        title: 'Beneficios Dentales',
        icon: require('../assets/dental.webp'),
        description: ['60% de descuento en tratamientos dentales en Sanasalud y UnoSalud.', 'Bonificación en centros como Integramédica, RedSalud y Red Dental Consalud.']
      },
      {
        title: 'Reembolso Running',
        icon: require('../assets/costo0.webp'),
        description: ['Reembolso del 50% en inscripciones a corridas en Chile, con tope de 12 bonificaciones anuales.']
      },
      {
        title: 'Bonificación en Ópticas',
        icon: require('../assets/optica.webp'),
        description: ['15% de descuento en lentes ópticos (armazones y cristales) y lentes de sol en Rotter & Krauss.']
      },
      {
        title: 'Reembolso Mountainbike',
        icon: require('../assets/ambulatoria.webp'),
        description: ['Bonificación del 50% en inscripciones a competencias de mountainbike, con un tope de 12 bonificaciones anuales.']
      },
      {
        title: 'Descuento en Farmacia',
        icon: require('../assets/farmacia.webp'),
        description: ['30% de descuento en el Recetario Magistral de Salcobrand.', '20% en medicamentos Medipharm y bioequivalentes.']
      },
      {
        title: 'Vacunas',
        icon: require('../assets/telemedicina.webp'),
        description: ['10% de bonificación en todas las vacunas del programa de inmunización en Megasalud.']
      }
    ],
    'colmena': [
      {
        title: 'Beneficios COVID-19',
        icon: require('../assets/hospitalaria.webp'),
        description: ['Sin costo en pruebas de detección de COVID-19 (PCR) y test rápido de anticuerpos.', 'Consultas médicas por videollamada sin costo con Doctor Online.', 'Cobertura completa en hospitalizaciones por diagnóstico confirmado de COVID-19 en la Red CAEC.']
      },
      {
        title: 'Atención Dental en Regiones',
        icon: require('../assets/dental.webp'),
        description: ['Red de convenios con clínicas dentales y especialistas en todo Chile.']
      },
      {
        title: 'Atención Kinesiológica',
        icon: require('../assets/kinesiologia_1.webp'),
        description: ['Sin límite de sesiones ni topes anuales en Medicien.']
      },
      {
        title: 'Atención de Salud Mental',
        icon: require('../assets/ambulatoria.webp'),
        description: ['Centro de Salud Mental Medicien con tarifas preferenciales.']
      },
      {
        title: 'Bonos Sin Costo',
        icon: require('../assets/costo0.webp'),
        description: ['Acceso a exámenes de laboratorio y procedimientos en centros como Blanco, Integramédica, y más.']
      },
      {
        title: 'Línea Telefónica Colmena Mujer',
        icon: require('../assets/telemedicina.webp'),
        description: ['Asesoría médica telefónica sobre síntomas, medicamentos y salud en general.']
      },
      {
        title: 'Millas LATAM Pass',
        icon: require('../assets/costo0.webp'),
        description: ['Acumula millas LATAM Pass al comprar en tiendasaludable.cl con excedentes.']
      },
      {
        title: 'Colmena Doctor',
        icon: require('../assets/ambulatoria.webp'),
        description: ['Servicio de orientación médica telefónica disponible las 24 horas del día, los 365 días del año.']
      },
      {
        title: 'Atención Dental en Santiago',
        icon: require('../assets/dental.webp'),
        description: ['Diagnóstico inicial sin costo, con radiografía panorámica para afiliados Colmena.']
      },
      {
        title: 'Descuento en Farmacias',
        icon: require('../assets/farmacia.webp'),
        description: ['30% de descuento en Recetario Magistral.', '25% en productos Cluny.', '20% en productos naturales.', '12% en insumos médicos y medicamentos.', '10% en tinturas y maquillaje.', '5% en cuidado capilar.']
      }
    ],
    'vidatres': [
      {
        title: 'Beneficios Dentales',
        icon: require('../assets/dental.webp'),
        description: ['Hasta 60% de descuento en centros dentales a lo largo de Chile.', 'Convenios con Vidaintegra, UnoSalud, Megasalud, y otros.']
      },
      {
        title: 'Descuentos en Ópticas',
        icon: require('../assets/optica.webp'),
        description: ['15% de descuento en receta óptica (armazones y cristales).', '10% de descuento en lentes de sol.', '10% de descuento en lentes de contacto no desechables.']
      },
      {
        title: 'Beneficio cuenta conocida',
        icon: require('../assets/costo0.webp'),
        description: ['Conoce anticipadamente el valor exacto de procedimientos como Lasik, Facoeresis y Polisomnografía a domicilio.']
      },
      {
        title: 'Plan Preventivo de Isapres',
        icon: require('../assets/telemedicina.webp'),
        description: ['Cuatro programas: Prevención del embarazo, Lactantes y Niños, Adultos, y Adultos Mayores.', 'Acceso a exámenes con bonos gratuitos en prestadores asignados por la Isapre.']
      },
      {
        title: 'Beneficios Covid-19',
        icon: require('../assets/hospitalaria.webp'),
        description: ['Acceso automático a la Cobertura Adicional para Enfermedades Catastróficas (CAEC).', 'No pagarás más de 126 UF por hospitalización.', 'Cobertura completa de insumos médicos relacionados con COVID-19.', 'Examen de detección de COVID-19 gratuito dentro de la red preferente.']
      },
      {
        title: 'Hasta 30% de Dcto. Descuento en Farmacia',
        icon: require('../assets/farmacia.webp'),
        description: ['30% de descuento en Recetario Magistral.', '25% de descuento en medicamentos Medipharm y Vademécum Especial.', '20% de descuento en medicamentos genéricos y vitaminas.']
      }
    ],
    'banmedica': [
      {
        title: 'Beneficios Dentales',
        icon: require('../assets/dental.webp'),
        description: ['Hasta 60% de descuento en centros dentales a lo largo de Chile.', 'Convenios con Vidaintegra, UnoSalud, Megasalud, y otros.']
      },
      {
        title: 'Descuentos en Ópticas',
        icon: require('../assets/optica.webp'),
        description: ['15% de descuento en receta óptica (armazones y cristales).', '10% de descuento en lentes de sol.', '10% de descuento en lentes de contacto no desechables.']
      },
      {
        title: 'Beneficio cuenta conocida',
        icon: require('../assets/costo0.webp'),
        description: ['Conoce anticipadamente el valor exacto de procedimientos como Lasik, Facoeresis y Polisomnografía a domicilio.']
      },
      {
        title: 'Plan Preventivo de Isapres',
        icon: require('../assets/telemedicina.webp'),
        description: ['Cuatro programas: Prevención del embarazo, Lactantes y Niños, Adultos, y Adultos Mayores.', 'Acceso a exámenes con bonos gratuitos en prestadores asignados por la Isapre.']
      },
      {
        title: 'Beneficios Covid-19',
        icon: require('../assets/hospitalaria.webp'),
        description: ['Acceso automático a la Cobertura Adicional para Enfermedades Catastróficas (CAEC).', 'No pagarás más de 126 UF por hospitalización.', 'Cobertura completa de insumos médicos relacionados con COVID-19.', 'Examen de detección de COVID-19 gratuito dentro de la red preferente.']
      },
      {
        title: 'Hasta 30% de Dcto. Descuento en Farmacia',
        icon: require('../assets/farmacia.webp'),
        description: ['30% de descuento en Recetario Magistral.', '25% de descuento en medicamentos Medipharm y Vademécum Especial.', '20% de descuento en medicamentos genéricos y vitaminas.']
      }
    ],
    'esencial': [
      {
        title: 'Reembolso Cancha',
        icon: require('../assets/costo0.webp'),
        description: ['Reembolso disponible para el alquiler de canchas a través de Easycancha.']
      },
      {
        title: 'Atención Nutricional',
        icon: require('../assets/ambulatoria.webp'),
        description: ['Sin límite de consultas con tu nutricionista, cubiertas al porcentaje indicado en tu plan.']
      },
      {
        title: 'Beneficio Betterfly',
        icon: require('../assets/telemedicina.webp'),
        description: ['Acceso a desafíos y herramientas de bienestar a partir del 1 de junio de 2022.']
      },
      {
        title: 'Chequeos Alemana Sport',
        icon: require('../assets/kinesiologia_1.webp'),
        description: ['100% de cobertura para evaluaciones y programas de rendimiento deportivo personalizados.']
      },
      {
        title: 'Chequeos Alemana Esencial',
        icon: require('../assets/hospitalaria.webp'),
        description: ['Cobertura total para programas de chequeos médicos en la Clínica Alemana de Santiago.']
      },
      {
        title: 'Beneficio Alemana Sport',
        icon: require('../assets/farmacia.webp'),
        description: ['Hasta 40% de descuento en evaluaciones y masajes deportivos, así como en planes de entrenamiento personalizados.']
      }
    ]
  };
  return benefitsByIsapre[isapre?.toLowerCase()] || defaultBenefits;
};
   

  const renderHeaderContent = () => (
    <div className={`bg-white p-4 ${isSmallScreen ? 'flex flex-col items-start' : 'flex justify-between items-center'}`}>
      <div className={`flex ${isSmallScreen ? 'flex-col items-start w-full' : 'items-center'}`}>
        {getIsapreLogo(planDetails?.isapre || 'NuevaMasvida') && (
          <img 
            src={getIsapreLogo(planDetails?.isapre || 'NuevaMasvida')} 
            alt={`${planDetails?.isapre || 'Isapre'} logo`} 
            className={`${isSmallScreen ? 'mb-2 w-30 h-10' : 'mr-5 w-30 h-16'}`} 
          />
        )}
        <div>
          <h2 className={`font-bold ${isSmallScreen ? 'text-xl' : 'text-2xl'}`}>{planDetails?.plan || 'Plan de Salud'}</h2>
          <p className={isSmallScreen ? 'text-sm' : ''}>Puntaje del plan: {planDetails?.nota || 'Libre Elección'} puntos</p>
        </div>
      </div>
      {isSmallScreen && <hr className="w-full border-t border-gray-300 my-2" />}
      <div className={`${isSmallScreen ? 'w-full' : ''} text-right`}>
        <p className={`font-bold ${isSmallScreen ? 'text-2xl mb-2' : 'text-xl'}`}>${planDetails?.precio_total_clp?.toLocaleString('es-CL') || 'Libre Elección'}/mes</p>
        <button 
          className={`bg-red-500 text-white px-4 py-2 rounded font-bold transition-all ${isRequestButtonPulsing ? 'scale-110' : 'scale-100'}`} 
          style={{ width: isSmallScreen ? '100%' : 'auto' }}
          onClick={() => setActiveTab('request')}
        >
          SOLICITAR PLAN A {(planDetails?.isapre || 'ISAPRE').toUpperCase()}
        </button>
      </div>
    </div>
  );

  const renderTabs = () => (
    <div className={`flex ${isSmallScreen ? 'justify-between' : ''} border-b mb-4`}>
      {[
        { key: 'general', label: 'General', icon: require('../assets/general.webp') },
        { key: 'contract', label: 'Contrato', icon: require('../assets/contrato.webp') },
        { key: 'videocall', label: 'Atención en vivo', icon: require('../assets/envivo.webp') },
        { key: 'request', label: 'Solicitar', icon: require('../assets/solicitar.webp') }
      ].map(tab => (
        <button
          key={tab.key}
          onClick={() => setActiveTab(tab.key)}
          className={`flex-1 p-2 font-bold flex items-center justify-center border-2 rounded-lg
            ${activeTab === tab.key ? 'bg-white text-[#0e6fc5] border-[#0e6fc5]' : 'bg-[#f3f3f3] text-[#7a7272] border-gray-300 hover:bg-white hover:text-[#0e6fc5]'}
            ${activeTab === 'videocall' && tab.key !== 'videocall' ? 'bg-black text-white' : ''}`}
          style={{ 
            margin: '2px', 
            transition: 'all 0.3s ease',
          }}
        >
          <img 
            src={tab.icon} 
            alt={`${tab.label} icon`} 
            className={`${isSmallScreen ? 'w-8 h-8' : 'w-12 h-12 mr-2'}`}
          />
          {!isSmallScreen && tab.label}
        </button>
      ))}
    </div>
  );

  const renderGeneralContent = () => {
  if (!planDetails) return null;

  const prestadores = planDetails.prestadores ? planDetails.prestadores.split('\n') : [];

  const sortedPrestadores = prestadores.map(prestador => {
    const normalizedPrestador = normalize(prestador);
    const hospitalaria = coverageData.hospitalaria.find(c => normalize(c.prestador) === normalizedPrestador) || {};
    const coberturaNumerica = parseFloat(hospitalaria.cobertura) || 0;
    return { prestador, cobertura: coberturaNumerica, isSelected: prestador.includes(planDetails.clinica_seleccionada) };
  });

  sortedPrestadores.sort((a, b) => {
    if (a.isSelected) return -1;
    if (b.isSelected) return 1;
    return b.cobertura - a.cobertura;
  });

  return (
    <div className={`p-4 ${isSmallScreen ? 'flex flex-col' : 'flex'}`}>
      <div className={`${isSmallScreen ? 'w-full' : 'w-8/12'} ${!isSmallScreen && 'pr-4 border-r border-gray-200'}`}>
        <h3 className="text-lg font-bold mb-4" style={{ color: '#3ba5d9' }}>Características de este plan</h3>
        <p className="mb-4">
          <span className="font-semibold text-green-600">Cobertura {planDetails.tipo_de_plan}</span><br />
          Este plan es {planDetails.tipo_de_plan} y está pensado para que idealmente te atiendas en los siguientes lugares de preferencia:
        </p>
        <div className={`${isSmallScreen ? 'overflow-x-auto' : ''}`}>
          <table className="w-full mb-4" style={{ fontSize: '14px', borderCollapse: 'separate', borderSpacing: '0 8px' }}>
            <thead>
              <tr className="bg-gray-100">
                <th className="p-2 text-left">Prestador</th>
                <th className="p-2 text-center">Hospitalaria</th>
                <th className="p-2 text-center">Ambulatoria</th>
                <th className="p-2 text-center">Urgencia</th>
              </tr>
            </thead>
            <tbody>
              {sortedPrestadores.map((prestadorData, index) => {
                const { prestador, isSelected } = prestadorData;
                const normalizedPrestador = normalize(prestador);
                const hospitalaria = coverageData.hospitalaria.find(c => normalize(c.prestador) === normalizedPrestador);
                const ambulatoria = coverageData.ambulatoria.find(c => normalize(c.prestador) === normalizedPrestador);
                const urgencia = coverageData.urgencia.find(c => normalize(c.prestador) === normalizedPrestador);

                return (
                  <tr
                    key={index}
                    className={`${index % 2 === 0 ? 'bg-white' : 'bg-gray-50'} hover:bg-gray-100`}
                  >
                    <td className="p-2">
                      {isSelected && <CheckCircle className="text-green-500 mr-2 inline-block" />}
                      {prestador.trim()}
                    </td>
                    <td className="p-2 text-center">
                      {hospitalaria ? `${hospitalaria.cobertura}` : `Libre Elección ${planDetails.cobertura_libre_eleccion_hospitalaria || '60'}%`}
                    </td>
                    <td className="p-2 text-center">
                      {ambulatoria ? `${ambulatoria.cobertura}` : `Libre Elección ${planDetails.cobertura_libre_eleccion_ambulatoria || '50'}%`}
                    </td>
                    <td className="p-2 text-center">
                      {urgencia ? `${urgencia.cobertura}` : 'Libre Elección'}
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
          <div className="border-t border-gray-200 mt-4 pt-4">
            <h4 className="font-semibold mb-2" style={{ color: '#3ba5d9' }}>Cobertura Libre Elección</h4>
            <p className="mb-2">Para el resto de los prestadores la cobertura es:</p>
            <div className={`flex ${isSmallScreen ? 'flex-col' : 'justify-start'} mb-4`}>
              <div className={`flex items-center border p-2 rounded ${isSmallScreen ? 'mb-2' : 'mr-2'}`}>
                <img src={require('../assets/hospitalaria.webp')} alt="Hospital icon" className="w-8 h-8 mr-2" />
                <div>
                  <p className="font-semibold" style={{ color: '#3ba5d9' }}>Cobertura Hospitalaria</p>
                  <p className="text-xs text-gray-600">(Operaciones, enfermedades, etc.)</p>
                </div>
                <p className="ml-2 text-lg font-bold">{`${planDetails.cobertura_libre_eleccion_hospitalaria || '60'}%`}</p>
              </div>
              <div className={`flex items-center border p-2 rounded ${isSmallScreen ? 'mb-2' : ''}`}>
                <img src={require('../assets/ambulatoria.webp')} alt="Ambulatory icon" className="w-8 h-8 mr-2" />
                <div>
                  <p className="font-semibold" style={{ color: '#3ba5d9' }}>Cobertura Ambulatoria</p>
                  <p className="text-xs text-gray-600">(Consultas médicas, exámenes, etc.)</p>
                </div>
                <p className="ml-2 text-lg font-bold">{`${planDetails.cobertura_libre_eleccion_ambulatoria || '50'}%`}</p>
              </div>
            </div>
            <div className="mb-4">
              <p className="font-semibold mb-2">Tendrás menos cobertura en:</p>
              <ul className="list-disc pl-5">
                <li>Cirugía bariátrica</li>
                <li>Cirugía refractiva</li>
                <li>Otras prestaciones restringidas</li>
              </ul>
            </div>

            <div className={`flex items-center mb-4 border p-2 rounded ${isSmallScreen ? 'flex-col' : ''}`}>
              <img src={require('../assets/tope.webp')} alt="Annual cap icon" className={`${isSmallScreen ? 'mb-2' : 'mr-4'} w-8 h-8`} />
              <p>Este plan cubre hasta <span className="font-bold">6.000 UF</span> al año por persona.<br />
              Exceder este monto se bonifica al mínimo legal</p>
            </div>

            <div className={`flex items-center mb-4 border p-2 rounded ${isSmallScreen ? 'flex-col' : ''}`}>
              <img src={require('../assets/parto.webp')} alt="Maternity icon" className={`${isSmallScreen ? 'mb-2' : 'mr-4'} w-8 h-8`} />
              <p>Este plan es con cobertura de parto</p>
            </div>
          </div>
        </div>
        <div className={`${isSmallScreen ? 'w-full mt-4' : 'w-4/12 pl-4'}`}>
          <h3 className="text-lg font-bold mb-4 text-center">Beneficios de <span className="text-red-600">{planDetails.isapre || 'Nueva Masvida'}</span></h3>
          {renderBenefits()}
        </div>
      </div>
    );
  };

  const renderBenefits = () => {
    const benefitsData = getBenefitsData(planDetails?.isapre);
    return (
      <div className={`grid ${isSmallScreen ? 'grid-cols-1' : 'grid-cols-2'} gap-4`}>
        {benefitsData.map((benefit, index) => (
          <div key={index} className="relative p-2 bg-white border rounded-lg shadow-sm hover:shadow-md cursor-pointer flex items-center casita group" style={{ fontSize: '.875rem', width: '100%', height: 'auto', boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)' }}>
            <img src={benefit.icon} alt={benefit.title} className="w-8 h-8 mr-2" />
            <span>{benefit.title}</span>
            <div className="absolute hidden group-hover:block top-full left-0 mt-2 w-full p-4 bg-white shadow-lg border rounded-lg z-10">
              <h4 style={{ color: '#0087cf', fontWeight: 'bold' }}>{benefit.title}</h4>
              <ul className="list-disc pl-4">
                {benefit.description.map((item, idx) => (
                  <li key={idx} dangerouslySetInnerHTML={{ __html: item }} />
                ))}
              </ul>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderContractContent = () => (
    <div className={`flex ${isSmallScreen ? 'flex-col' : ''}`}>
      <div className={`${isSmallScreen ? 'w-full' : 'w-2/3'} p-2.5 ${!isSmallScreen && 'border-r'}`}>
        <div className="bg-white p-2.5 border rounded-lg shadow-lg" style={{ overflow: 'hidden' }}>
          <div className="flex justify-between mb-2">
            <div>
              <button 
                disabled={pageNumber <= 1} 
                onClick={() => setPageNumber((prevPageNumber) => prevPageNumber - 1)}
                className="bg-blue-500 text-white p-1.25 rounded mr-2"
              >
                Anterior
              </button>
              <span>Página {pageNumber} de {numPages}</span>
              <button 
                disabled={pageNumber >= numPages} 
                onClick={() => setPageNumber((prevPageNumber) => prevPageNumber + 1)}
                className="bg-blue-500 text-white p-1.25 rounded ml-2"
              >
                Siguiente
              </button>
            </div>
            <div>
              <label htmlFor="pdfZoom" className="mr-2">Zoom:</label>
              <input 
                id="pdfZoom" 
                type="range" 
                min="1" 
                max="3" 
                step="0.1" 
                value={pdfScale} 
                onChange={(e) => setPdfScale(parseFloat(e.target.value))}
                className="w-24"
              />
            </div>
          </div>
          <div style={{ overflow: 'auto', maxHeight: '70vh' }}>
            <Document
              file={`/pdfs/${planDetails.codigo_plan}.pdf`}
              onLoadSuccess={({ numPages }) => setNumPages(numPages)}
              onLoadError={(error) => console.error('Error loading PDF:', error)}
            >
              <Page 
                pageNumber={pageNumber} 
                scale={pdfScale}
              />
            </Document>
          </div>
        </div>
      </div>
      <div className={`${isSmallScreen ? 'w-full mt-4' : 'w-1/3'} p-2.5 flex flex-col items-center justify-center`} style={{ height: isSmallScreen ? 'auto' : 'calc(200% + 35%)' }}>
        {!contractFormSubmitted ? (
          <>
            <h3 className="text-2xl font-bold mb-8 text-center">Solicitar</h3>
            <p className="mb-4 text-xs text-center">Contrata y/o recibe asesoría mediante un ejecutivo especializado</p>
            <form className="space-y-4 w-full" onSubmit={handleSubmit}>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Ingresa tu nombre"
                className="w-full p-2 border rounded"
                style={{ height: '40px' }}
              />
              <input
                type="text"
                name="rut"
                value={formData.rut}
                onChange={handleInputChange}
                placeholder="RUT"
                className="w-full p-2 border rounded"
                style={{ height: '40px' }}
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Correo Electrónico"
                className="w-full p-2 border rounded"
                style={{ height: '40px' }}
              />
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Teléfono"
                className="w-full p-2 border rounded"
                style={{ height: '40px' }}
              />
              <button 
                type="submit" 
                className={`w-full bg-pink-500 text-white p-2.5 rounded ${isSubmitting ? 'button-loading' : ''}`}
                disabled={isSubmitting}
                style={{ height: '40px' }}
              >
                {isSubmitting ? 'Enviando...' : buttonText}
              </button>
            </form>
          </>
        ) : (
          <div className="text-center flex flex-col items-center justify-center" style={{ width: '100%' }}>
            <h3 className="text-4xl font-bold mb-4 text-center">
              <span className="text-[#ef4444] font-bold">CONECTATE</span>{" "}
              <span className="text-black font-bold">EN VIVO</span>
            </h3>
            <h4 className="text-lg mb-4 text-center">
              <span className="text-black">Con un ejecutivo por</span>{" "}
              <span className="text-[#117eff] font-bold">Video en línea</span>
            </h4>
            <hr className="my-4 border-transparent" />
           <button 
  onClick={handleConnectClick}
  className={`bg-green-500 text-white font-bold rounded text-2xl transition-all shadow-lg ${isConnectButtonPulsing ? 'scale-110' : 'scale-100'}`}
  style={{ width: '300px', height: '75px' }}
>
  Conectar con ejecutivo
</button>
          </div>
        )}
      </div>
    </div>
  );

  const renderVideoCallContent = () => (
    <div className="relative" style={{ minHeight: '100vh', width: '100%' }}>
      <img 
        src={require('../assets/envivo2.webp')} 
        alt="En vivo background" 
        style={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '100%',
          height: '100%',
          objectFit: 'cover',
        }}
      />

      <div className="relative z-10 flex items-start justify-center" style={{ backgroundColor: 'rgba(0,0,0,0.5)' }}>
        {!videoCallFormSubmitted ? (
          <div className={`${isSmallScreen ? 'w-full' : 'w-1/2'} flex flex-col items-center justify-center p-4`}>
            <h3 className="text-4xl font-bold mb-4 text-center">
              <span className="text-[#ef4444]">CONECTATE</span>{" "}
              <span className="text-white">EN VIVO</span>
            </h3>
            <h4 className="text-lg mb-4 text-center text-white">
              <span className="text-white">Con un ejecutivo por</span>{" "}
              <span className="text-[#117eff] font-bold">Video en línea</span>
            </h4>
            <p className="text-center mb-4 text-gray-400">No necesitas activar tu cámara</p>
            <form 
              className="space-y-4 w-full"
              onSubmit={handleVideoCallSubmit}
              style={{ textAlign: 'center' }}
            >
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
                placeholder="Ingresa tu nombre"
                className="w-full p-4 border rounded"
                style={{ height: '40px' }}
              />
              <input
                type="text"
                name="rut"
                value={formData.rut}
                onChange={handleInputChange}
                placeholder="RUT"
                className="w-full p-4 border rounded"
                style={{ height: '40px' }}
              />
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleInputChange}
                placeholder="Correo Electrónico"
                className="w-full p-4 border rounded"
                style={{ height: '40px' }}
              />
              <input
                type="tel"
                name="phone"
                value={formData.phone}
                onChange={handleInputChange}
                placeholder="Teléfono"
                className="w-full p-4 border rounded"
                style={{ height: '40px' }}
              />
              <button 
                type="submit" 
                className="w-full bg-pink-500 text-white p-8 rounded font-bold"
                disabled={isSubmitting}
                style={{ height: '50px' }}
              >
                {isSubmitting ? 'Enviando...' : 'Enviar Formulario'}
              </button>
            </form>
          </div>
        ) : (
          <div className="text-center flex flex-col items-center justify-center">
            <h3 className="text-4xl font-bold mb-4">
              <span className="text-[#ef4444]">CONECTATE</span>{" "}
              <span className="text-white">EN VIVO</span>
            </h3>
            <h4 className="text-lg mb-4">
              <span className="text-white">Con un ejecutivo por</span>{" "}
              <span className="text-[#117eff] font-bold">Video en línea</span>
            </h4>
            <hr className="my-4 border-transparent" />
            <button 
              onClick={handleConnectClick}
              className={`bg-green-500 text-white font-bold rounded text-2xl transition-all ${isConnectButtonPulsing ? 'scale-110' : 'scale-100'}`}
              style={{ width: '400px', height: '75px' }}
            >
              CONECTAR
            </button>
          </div>
        )}
      </div>
    </div>
  );

  const renderRequestContent = () => (
    <div className="p-2.5">
      {!requestFormSubmitted ? (
        <>
          <h3 className="text-4xl font-bold mb-4 text-center">
            <span className="text-[#ef4444]">Solicitar</span>{" "}
            <span className="text-black">Plan a {planDetails?.isapre || 'ISAPRE'}</span>
          </h3>
          <h4 className="text-lg mb-4 text-center">
            <span className="text-black">Con un ejecutivo por</span>{" "}
            <span className="text-[#117eff] font-bold">Video en línea</span>
          </h4>
          <form 
            className={`space-y-4 ${isSmallScreen ? 'w-full' : 'w-1/2'} mx-auto`}
            onSubmit={handleSubmit}
            style={{ textAlign: 'center' }}
          >
            <input
              type="text"
              name="name"
              value={formData.name}
              onChange={handleInputChange}
              placeholder="Ingresa tu nombre"
              className="w-full p-4 border rounded"
              style={{ height: '40px' }}
            />
            <input
              type="text"
              name="rut"
              value={formData.rut}
              onChange={handleInputChange}
              placeholder="RUT"
              className="w-full p-4 border rounded"
              style={{ height: '40px' }}
            />
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleInputChange}
              placeholder="Correo Electrónico"
              className="w-full p-4 border rounded"
              style={{ height: '40px' }}
            />
            <input
              type="tel"
              name="phone"
              value={formData.phone}
              onChange={handleInputChange}
              placeholder="Teléfono"
              className="w-full p-4 border rounded"
              style={{ height: '40px' }}
            />
           <button 
              type="submit" 
              className={`w-full bg-pink-500 text-white p-4 rounded font-bold ${isSubmitting ? 'button-loading' : ''}`} 
              disabled={isSubmitting} 
              style={{ height: '60px', fontSize: '1.25rem' }}
            >
              {isSubmitting ? 'Enviando...' : 'ENVIAR SOLICITUD'}
            </button>
          </form>
        </>
      ) : (
        <div className="text-center flex flex-col items-center justify-center">
          <h3 className="text-4xl font-bold mb-4 text-center">
            <span className="text-[#ef4444]">CONECTATE</span>{" "}
            <span className="text-black">EN VIVO</span>
          </h3>
          <h4 className="text-lg mb-4 text-center">
            <span className="text-black">Con un ejecutivo por</span>{" "}
            <span className="text-[#117eff] font-bold">Video en línea</span>
          </h4>
          <hr className="my-4 border-transparent" />
          <button 
            onClick={handleConnectClick}
            className={`bg-green-500 text-white font-bold rounded text-2xl transition-all ${isConnectButtonPulsing ? 'scale-110' : 'scale-100'}`}
            style={{ width: '400px', height: '75px' }}
          >
            CONECTAR
          </button>
        </div>
      )}
    </div>
  );

  const renderTabContent = () => {
    switch(activeTab) {
      case 'general':
        return renderGeneralContent();
      case 'contract':
        return renderContractContent();
      case 'videocall':
        return renderVideoCallContent();
      case 'request':
        return renderRequestContent();
      default:
        return null;
    }
  };

return (
    <Dialog 
      open={isOpen} 
      onOpenChange={(open) => open ? null : onClose()}
      style={{ transition: 'opacity 0.5s ease-in-out', opacity: isOpen ? 1 : 0 }}
    >
      <DialogContent 
        className={`mx-auto bg-white shadow-lg rounded-lg overflow-hidden ${isSmallScreen ? 'w-full h-full' : 'max-w-6xl max-h-[90vh]'}`}
      >
        <DialogTitle className="sr-only">{planDetails?.plan || 'Detalles del Plan de Salud'}</DialogTitle>
        <DialogDescription className="sr-only">Información detallada sobre el plan de salud seleccionado</DialogDescription>
        
        <div className={`overflow-y-auto ${isSmallScreen ? 'h-full' : 'max-h-[calc(90vh-120px)]'}`}>
          {renderHeaderContent()}
          {renderTabs()}
          {renderTabContent()}
        </div>
        
        <div className="bg-blue-100 p-2.5 flex items-center">
          <AlertCircle className="text-blue-500 mr-1.25 flex-shrink-0" />
          <p className="text-xs">
            Tu 7% de salud es ${planDetails?.precio_minimo_clp?.toLocaleString('es-CL')}. 
            Legalmente no puedes generar más de 10% de excedentes. 
            Debes buscar por sobre ${planDetails?.precio_minimo_clp?.toLocaleString('es-CL')}/mes.
          </p>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default PlanDetailModal;