import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { Check, X } from 'lucide-react';
import FamiliaImage from './assets/familia.webp';

const MAX_PRICE = 800000;

const AdvancedFilters = ({ onFilterChange, precioMinimoFilterMenu }) => {
  const isapres = useMemo(() => [
    'Banmedica', 'Consalud', 'Colmena', 'Vidatres', 'Nueva Masvida', 'Esencial'
  ], []);

  const tiposDePlan = useMemo(() => [
    'Preferente', 'Libre Eleccion', 'Cerrado'
  ], []);

  const clinicas = useMemo(() => [
    "Clínica Alemana",
    "Clínica Alemana de Osorno", "Clínica Alemana de Temuco", "Clínica Alemana de Valdivia",
    "Clínica Andes Salud Concepción", "Clínica Andes Salud El Loa", "Clínica Andes Salud Puerto Montt",
    "Clínica Antofagasta", "Clínica Atacama", "Clínica Biobío", "Clínica Bupa Antofagasta",
    "Clínica Bupa Reñaca", "Clínica Bupa Santiago", "Clínica Ciudad del Mar",
    "Clínica Cordillera", "Clínica Dávila", "Clínica Dávila Vespucio", "Clínica Fusat",
    "Clínica Hospital del Profesor", "Clínica Indisa", "Clínica Isamédica", 
    "Clínica La Portada", "Clínica Las Condes", "Clínica Lircay", "Clínica Lircay de Talca",
    "Clínica Los Andes de Los Ángeles", "Clínica Los Carrera", "Clínica Meds", "Clínica Puerto Montt",
    "Clínica Puerto Varas", "Clínica RCR de Atacama", "Clínica RedSalud Elqui", "Clínica RedSalud Iquique",
    "Clínica RedSalud Magallanes", "Clínica RedSalud Mayor de Temuco", "Clínica RedSalud Providencia",
    "Clínica RedSalud Rancagua", "Clínica RedSalud Santiago", "Clínica RedSalud Valparaíso",
    "Clínica RedSalud Vitacura", "Clínica Reñaca", "Clínica San Carlos de Apoquindo",
    "Clínica San José de Arica", "Clínica Sanatorio Alemán", "Clínica Santa María", "Clínica Tarapacá",
    "Clínica UC", "Clínica Universidad de Los Andes", 
    "Clínica Universitaria de Puerto Montt", "Hospital Clínico UC", "Hospital Clínico Universidad de Chile",
    "Hospital Clínico Viña del Mar", "Hospital Clínico del Sur",
    "Hospital de Niños", "Integramédica", "Vidaintegra"
  ], []);

  const coberturas = useMemo(() => [60, 70, 80, 90, 100], []);

  const [selectedIsapres, setSelectedIsapres] = useState(isapres);
  const [selectedPlans, setSelectedPlans] = useState(tiposDePlan);
  const [selectedClinica, setSelectedClinica] = useState('');
  const [clinicaSearch, setClinicaSearch] = useState('');
  const [precioMinimoCLP, setPrecioMinimoCLP] = useState(precioMinimoFilterMenu);
  const [cobertura_hospitalaria_minima, setCobertura_hospitalaria_minima] = useState([]);
  const [cobertura_ambulatoria_minima, setCobertura_ambulatoria_minima] = useState([]);
  const [isDataReady, setIsDataReady] = useState(false);
  const [showClinicaDropdown, setShowClinicaDropdown] = useState(false);
  const dropdownRef = useRef(null);
  const filtersRef = useRef(null);

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  const [isFilterVisible, setIsFilterVisible] = useState(false);
  const [isSliderDragging, setIsSliderDragging] = useState(false);

  useEffect(() => {
    const checkScreenSize = () => {
      setIsSmallScreen(window.innerWidth <= 1100);
    };

    checkScreenSize();
    window.addEventListener('resize', checkScreenSize);

    return () => {
      window.removeEventListener('resize', checkScreenSize);
    };
  }, []);

  const toggleFilter = () => {
    setIsFilterVisible(!isFilterVisible);
  };

  useEffect(() => {
    setPrecioMinimoCLP(precioMinimoFilterMenu);
  }, [precioMinimoFilterMenu]);

  useEffect(() => {
    if (selectedIsapres.length > 0 && selectedPlans.length > 0 && precioMinimoCLP !== undefined) {
      setIsDataReady(true);
    }
  }, [selectedIsapres, selectedPlans, precioMinimoCLP]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowClinicaDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownRef]);

  const handleFilterChange = useCallback(() => {
    if (!isDataReady) return;

    const params = {
      isapre: selectedIsapres.length > 0 ? selectedIsapres.join(',') : '',
      tipo_de_plan: selectedPlans.length > 0 ? selectedPlans.join(',') : '',
      precio_minimo_clp: precioMinimoCLP !== undefined ? precioMinimoCLP : 0,
      clinicas: selectedClinica ? selectedClinica : '',
      cobertura_hospitalaria_minima: cobertura_hospitalaria_minima.length > 0 ? cobertura_hospitalaria_minima.join(',') : '',
      cobertura_ambulatoria_minima: cobertura_ambulatoria_minima.length > 0 ? cobertura_ambulatoria_minima.join(',') : ''
    };

    console.log('Parámetros enviados pablo:', params); // Agregar este log
    onFilterChange(params);
  }, [selectedIsapres, selectedPlans, precioMinimoCLP, selectedClinica, cobertura_hospitalaria_minima, cobertura_ambulatoria_minima, onFilterChange, isDataReady]);

  useEffect(() => {
    if (!isSliderDragging) {
      handleFilterChange();
    }
  }, [handleFilterChange, isSliderDragging]);

  const handleIsapreToggle = useCallback((isapre) => {
    setSelectedIsapres(prevSelectedIsapres => {
      const newSelection = prevSelectedIsapres.includes(isapre) 
        ? prevSelectedIsapres.filter(selectedIsapre => selectedIsapre !== isapre) 
        : [...prevSelectedIsapres, isapre];
      return newSelection;
    });
  }, []);

  const handlePlanToggle = useCallback((plan) => {
    setSelectedPlans(prevSelectedPlans => {
      const newSelection = prevSelectedPlans.includes(plan) 
        ? prevSelectedPlans.filter(selectedPlan => selectedPlan !== plan) 
        : [...prevSelectedPlans, plan];
      
      if (plan === 'Libre Eleccion') {
        setSelectedClinica('');
      }

      return newSelection;
    });
  }, []);

  const handleClinicaSelect = useCallback((clinica) => {
    setSelectedClinica(prevSelectedClinica => {
      const newSelection = prevSelectedClinica === clinica ? '' : clinica;
      
      if (newSelection) {
        setSelectedPlans(prevSelectedPlans => prevSelectedPlans.filter(plan => plan !== 'Libre Eleccion'));
      }

      return newSelection;
    });
    setClinicaSearch('');
    setShowClinicaDropdown(false);
  }, []);

  const handleClinicaSearchChange = useCallback((event) => {
    setClinicaSearch(event.target.value);
    setShowClinicaDropdown(true);
  }, []);

  const handleRemoveSelectedClinica = useCallback(() => {
    setSelectedClinica('');
    setClinicaSearch('');
  }, []);

  const handlePriceChange = useCallback((event) => {
    const newPrice = Number(event.target.value);
    setPrecioMinimoCLP(newPrice);
  }, []);

  const handleSliderMouseDown = useCallback(() => {
    setIsSliderDragging(true);
  }, []);

  const handleSliderMouseUp = useCallback(() => {
    setIsSliderDragging(false);
    handleFilterChange();
  }, [handleFilterChange]);

  const handleCoberturaHospitalariaChange = useCallback((cobertura) => {
    const valueToSend = cobertura === 60 ? 10 : cobertura;
    setCobertura_hospitalaria_minima(prevCoberturas => {
      if (prevCoberturas.includes(valueToSend)) {
        return prevCoberturas.filter(c => c !== valueToSend);
      } else {
        return [...prevCoberturas, valueToSend].sort((a, b) => a - b);
      }
    });
  }, []);

  const handleCoberturaAmbulatoriaChange = useCallback((cobertura) => {
    if (cobertura < 90) {
      const valueToSend = cobertura === 60 ? 10 : cobertura;
      setCobertura_ambulatoria_minima(prevCoberturas => {
        if (prevCoberturas.includes(valueToSend)) {
          return prevCoberturas.filter(c => c !== valueToSend);
        } else {
          return [...prevCoberturas, valueToSend].sort((a, b) => a - b);
        }
      });
    }
  }, []);

  const formatPrice = useCallback((price) => {
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(price);
  }, []);

  const renderCheckboxButton = useCallback((item, selectedItems, handleToggle) => (
    <div 
      key={item}
      className={`flex items-center p-2 rounded cursor-pointer text-sm ${
        selectedItems.includes(item) ? 'bg-blue-100' : 'bg-gray-100'
      } hover:bg-[#e5e7eb] transition-all duration-200`}
      onClick={() => handleToggle(item)}
      style={{ height: '32px' }}
    >
      <div className={`w-5 h-5 mr-2 border rounded flex items-center justify-center ${
        selectedItems.includes(item) ? 'bg-blue-500 border-blue-500' : 'border-gray-400'
      }`}>
        {selectedItems.includes(item) && <Check size={16} color="white" />}
      </div>
      <span>{item}</span>
    </div>
  ), []);

  const renderCoberturaButton = useCallback((cobertura, selectedCoberturas, handleChange, isAmbulatorio = false) => {
    const valueToCheck = cobertura === 60 ? 10 : cobertura;
    return (
      <button
        key={cobertura}
        className={`rounded-full w-12 h-12 ${
          selectedCoberturas.includes(valueToCheck) ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'
        } hover:bg-[#e5e7eb] transition-all duration-200 ${
          isAmbulatorio && cobertura >= 90 ? 'opacity-50 cursor-not-allowed' : ''
        }`}
        onClick={() => handleChange(cobertura)}
        disabled={isAmbulatorio && cobertura >= 90}
        title={isAmbulatorio && cobertura >= 90 ? "No existen planes con esta cobertura" : ""}
      >
        {cobertura}%
      </button>
    );
  }, []);

  const normalizeString = (str) => {
    return str.toLowerCase().normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  const filteredClinicas = useMemo(() => {
    const normalizedSearch = normalizeString(clinicaSearch);
    return clinicas.filter(clinica => 
      normalizeString(clinica).includes(normalizedSearch)
    );
  }, [clinicas, clinicaSearch]);

  const filterContent = (
    <div 
      ref={filtersRef} 
      className={`bg-white p-4 rounded-lg shadow-lg overflow-y-auto ${!isSmallScreen ? 'sticky-top' : ''}`} 
      style={{ maxHeight: 'calc(200vh - 20px)', position: !isSmallScreen ? 'sticky' : 'relative', top: !isSmallScreen ? '20px' : 'initial' }} 
    >
      <img src={FamiliaImage} alt="Familia" className="w-full mb-4" />

      {/* Casita de Precio */}
      <div className="w-full rounded-md shadow-sm border border-gray-200 mb-4 overflow-hidden">
        <div className="bg-gray-100 p-3">
          <h3 className="text-base font-semibold text-blue-600">Precio</h3>
        </div>

        <div className="bg-white p-3">
          <p className="text-sm text-gray-600 mb-3">
            Tu 7% estimado: <span className="text-lg font-bold text-gray-800">{formatPrice(precioMinimoFilterMenu)}</span>
          </p>

          <div className="flex justify-between mb-3">
            <span className="text-sm text-gray-600">{formatPrice(precioMinimoCLP)}</span>
            <span className="text-sm text-gray-600">{formatPrice(MAX_PRICE)}</span>
          </div>
          <input
            type="range"
            min="0"
            max={MAX_PRICE}
            value={precioMinimoCLP}
            onChange={handlePriceChange}
            onMouseDown={handleSliderMouseDown}
            onMouseUp={handleSliderMouseUp}
            onTouchStart={handleSliderMouseDown}
            onTouchEnd={handleSliderMouseUp}
            className="w-full"
          />

          {precioMinimoCLP < precioMinimoFilterMenu && (
            <div className="bg-red-100 p-2 mt-3 rounded-md">
              <p className="text-sm font-bold text-[#631818]">Precio mínimo muy bajo.</p>
              <p className="text-sm text-[#842222]">Legalmente no puedes contratar un plan menor a tu 7% de salud.</p>
            </div>
          )}
        </div>
      </div>

      {/* Casita de Selección de Clínicas y Hospitales */}
      <div className="w-full rounded-md shadow-sm border border-gray-200 mb-4 overflow-visible">
        <div className="bg-gray-100 p-3">
          <h3 className="text-base font-semibold text-blue-600">Clínicas</h3>
        </div>

        <div className="bg-white p-3">
          <div className="mb-4 relative" ref={dropdownRef}>
            <input
              type="text"
              placeholder={selectedClinica ? '' : "Buscar clínica..."}
              value={selectedClinica || clinicaSearch}
              onChange={handleClinicaSearchChange}
              className="w-full p-2 border rounded hover:bg-[#e5e7eb] transition-all duration-200"
              readOnly={!!selectedClinica}
              onClick={() => setShowClinicaDropdown(!showClinicaDropdown)}
            />
            {selectedClinica && (
              <button
                onClick={handleRemoveSelectedClinica}
                className="absolute right-2 top-1/2 transform -translate-y-1/2 text-gray-500 hover:text-gray-700 hover:bg-[#e5e7eb] transition-all duration-200"
              >
                <X size={20} />
              </button>
            )}
            {!selectedClinica && showClinicaDropdown && (
              <div 
                className="absolute z-50 bg-white border rounded shadow-lg w-full max-h-60 overflow-y-auto mt-1" 
                style={{ 
                  zIndex: 9999, 
                  position: 'absolute',
                  top: '100%',
                  left: 0,
                  width: '100%'
                }}
              >
                {filteredClinicas.map(clinica => (
                  <div 
                    key={clinica}
                    className={`flex items-center p-2 rounded cursor-pointer ${
                      selectedClinica === clinica ? 'bg-blue-100' : 'bg-gray-100'
                    } hover:bg-[#e5e7eb] transition-all duration-200`}
                    onClick={() => handleClinicaSelect(clinica)}
                  >
                    <div className={`w-5 h-5 mr-2 border rounded flex items-center justify-center ${
                      selectedClinica === clinica ? 'bg-blue-500 border-blue-500' : 'border-gray-400'
                    }`}>
                      {selectedClinica === clinica && <Check size={16} color="white" />}
                    </div>
                    <span>{clinica}</span>
                  </div>
                ))}
              </div>
            )}
          </div>

          <div className="flex flex-col gap-2">
            {tiposDePlan.map(plan => renderCheckboxButton(plan, selectedPlans, handlePlanToggle))}
          </div>
        </div>
      </div>

      {/* Casita de Coberturas */}
      <div className="w-full rounded-md shadow-sm border border-gray-200 mb-4 overflow-hidden">
        <div className="bg-gray-100 p-3">
          <h3 className="text-base font-semibold text-blue-600">Coberturas</h3>
        </div>

        <div className="bg-white p-3">
          <div className="mb-4">
            <p className="text-sm font-semibold mb-2">Cobertura Hospitalaria:</p>
            <div className="flex justify-between">
              {coberturas.map(cobertura => renderCoberturaButton(cobertura, cobertura_hospitalaria_minima, handleCoberturaHospitalariaChange))}
            </div>
          </div>

          <div>
            <p className="text-sm font-semibold mb-2">Cobertura Ambulatoria:</p>
            <div className="flex justify-between">
              {coberturas.map(cobertura => renderCoberturaButton(cobertura, cobertura_ambulatoria_minima, handleCoberturaAmbulatoriaChange, true))}
            </div>
          </div>
        </div>
      </div>

      {/* Casita de Isapres */}
      <div className="w-full rounded-md shadow-sm border border-gray-200 mb-4 overflow-hidden">
        <div className="bg-gray-100 p-3">
          <h3 className="text-base font-semibold text-blue-600">Isapres</h3>
        </div>

        <div className="bg-white p-3">
          <div className="flex flex-col gap-2">
            {isapres.map(isapre => renderCheckboxButton(isapre, selectedIsapres, handleIsapreToggle))}
          </div>
        </div>
      </div>

      {/* Casita de Ley Corta */}
      <div className="w-full rounded-md shadow-sm border border-gray-200 mb-4 overflow-hidden">
        <div className="bg-gray-100 p-3">
          <h3 className="text-base font-semibold text-blue-600">Ley Corta</h3>
        </div>

        <div className="bg-white p-3">
          <p className="text-sm text-gray-600 mb-3">
            A partir de agosto de 2024, ya no está permitido acumular excedentes en tu plan de Isapre o Fonasa. Esto significa que el valor mínimo del plan de salud debe ajustarse al 7% de tu cotización.
          </p>
        </div>
      </div>

    </div>
  );

  return (
    <>
      {isSmallScreen ? (
        <>
          <button
            className="fixed bottom-[70px] left-1/2 transform -translate-x-1/2 z-50 bg-blue-500 text-white py-3 px-8 text-center rounded-lg shadow-md font-bold text-lg"
            onClick={toggleFilter}
            style={{
              width: 'calc(100% - 40px)', 
              maxWidth: '400px', 
            }}
          >
            Filtros avanzados ▼
          </button>
          <div 
            className={`
              fixed top-0 left-0 h-full w-5/6 bg-white overflow-y-auto transition-transform duration-300 ease-in-out z-40 shadow-lg
              ${isFilterVisible ? 'transform translate-x-0' : 'transform -translate-x-full'}
            `}
          >
            {filterContent}
          </div>
        </>
      ) : (
        <div className="static">
          {filterContent}
        </div>
      )}
    </>
  );
};

export default AdvancedFilters;