import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { X, ChevronDown, User, Plus, Minus } from 'lucide-react';
import axios from 'axios';
import './FilterMenu.css';

const transformRegion = (selectedRegion) => {
  switch (selectedRegion) {
    case 'Metropolitana': return 'RM';
    case 'Arica y Parinacota':
    case 'Tarapacá':
    case 'Antofagasta':
    case 'Atacama':
    case 'Coquimbo': return 'Norte';
    case 'Valparaíso': return 'Quinta';
    case 'O\'Higgins':
    case 'Maule': return 'Centro';
    case 'Ñuble':
    case 'Biobío': return 'Octava';
    case 'Araucanía':
    case 'Los Ríos':
    case 'Los Lagos':
    case 'Aysén':
    case 'Magallanes': return 'Sur';
    default: return undefined;
  }
};

const calculateMinPrice = (income1, income2, ufValue) => {
  const parsedIncome1 = parseFloat((income1 || '').replace(/\./g, '')) || 0;
  const parsedIncome2 = parseFloat((income2 || '').replace(/\./g, '')) || 0;
  console.log("Valor de la UF antes del cálculo:", ufValue);
  const calculatedTotalIncome = parsedIncome1 + parsedIncome2;
  let calculatedMinPrice = Math.round(calculatedTotalIncome * 0.07);
  const maxPrice = (ufValue * 84.3) * 0.07;
  calculatedMinPrice = Math.min(calculatedMinPrice, maxPrice);
  console.log(`Cálculo de precio mínimo: Ingresos (${parsedIncome1} + ${parsedIncome2}) = ${calculatedTotalIncome}, Precio Mínimo: ${calculatedMinPrice}`);
  return calculatedMinPrice;
};

const formatIncome = (value) => {
  return value.replace(/\D/g, '').replace(/\B(?=(\d{3})+(?!\d))/g, '.');
};

const FilterMenu = ({ onFilterChange, onMinPriceChange, onSliderPriceChange }) => {
  const [filters, setFilters] = useState({
    region: undefined,
    income: undefined,
    edad_cotizante1: undefined,
    gender: undefined,
  });
  const [dependents, setDependents] = useState([]);
  const [spouse, setSpouse] = useState(null);
  const [showInsured, setShowInsured] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const insuredRef = useRef(null);
  const [deviceType, setDeviceType] = useState('mobile');
  const [totalIncome, setTotalIncome] = useState(0);
  const [minPrice, setMinPrice] = useState(0);
  const [precioMinimoCLP, setPrecioMinimoCLP] = useState(0);
  const [precioslider, setPrecioslider] = useState(0);
  const [RegionSeleccionada, setRegionSeleccionada] = useState(undefined);
  const [ufValue, setUfValue] = useState(0);
  const debounceTimerRef = useRef(null);

  const regions = useMemo(() => [
    { value: 'Metropolitana', label: 'Metropolitana' },
    { value: 'Arica y Parinacota', label: 'Arica y Parinacota' },
    { value: 'Tarapacá', label: 'Tarapacá' },
    { value: 'Antofagasta', label: 'Antofagasta' },
    { value: 'Atacama', label: 'Atacama' },
    { value: 'Coquimbo', label: 'Coquimbo' },
    { value: 'Valparaíso', label: 'Valparaíso' },
    { value: 'O\'Higgins', label: 'O\'Higgins' },
    { value: 'Maule', label: 'Maule' },
    { value: 'Ñuble', label: 'Ñuble' },
    { value: 'Biobío', label: 'Biobío' },
    { value: 'Araucanía', label: 'Araucanía' },
    { value: 'Los Ríos', label: 'Los Ríos' },
    { value: 'Los Lagos', label: 'Los Lagos' },
    { value: 'Aysén', label: 'Aysén' },
    { value: 'Magallanes', label: 'Magallanes' }
  ], []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 640) setDeviceType('mobile');
      else if (window.innerWidth < 768) setDeviceType('tablet');
      else setDeviceType('desktop');
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (insuredRef.current && !insuredRef.current.contains(event.target)) {
        setShowInsured(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    const fetchUfValue = async () => {
      try {
        const response = await axios.get('https://infoplan.cl:4000/api/valor-uf');
        setUfValue(response.data.uf);
        console.log('Valor de la UF obtenido:', response.data.uf);
      } catch (error) {
        console.error('Error al obtener el valor de la UF:', error);
      }
    };
    fetchUfValue();
  }, []);

  const handleInputChange = useCallback((field, value) => {
    setFilters(prevFilters => ({ ...prevFilters, [field]: value || undefined }));
    if (field === 'region') {
      setRegionSeleccionada(value || undefined);
    }
  }, []);

  const calculatePrices = useCallback(() => {
    let validDependents = dependents.filter(d => d.age !== undefined && d.age !== '');
    if (spouse && spouse.age && (!spouse.income || spouse.income === '')) {
      validDependents = [...validDependents, { age: spouse.age }];
    }
    const filterParams = {
      region: transformRegion(filters.region) || undefined,
      edad_cotizante1: filters.edad_cotizante1 || '18',
      edad_cotizante2: (spouse && spouse.age && spouse.income) ? spouse.age : undefined,
      edades_cargas: validDependents.length > 0 ? validDependents.map(d => d.age).join(',') : undefined,
      precio_minimo_clp: precioMinimoCLP > 0 ? precioMinimoCLP.toString() : undefined,
    };
    console.log("Parámetros de filtro antes de enviar:", filterParams);
    onFilterChange(filterParams);
  }, [filters, spouse, dependents, precioMinimoCLP, onFilterChange]);

  const handleCalculateClick = () => {
    console.log("Botón Calcular precios clickeado");
    console.log('Valor de la UF utilizado para el cálculo en handleCalculateClick:', ufValue);
    const newMinPrice = calculateMinPrice((filters.income || '').replace(/\./g, ''), spouse?.income, ufValue);
    setMinPrice(newMinPrice);
    setPrecioMinimoCLP(newMinPrice);
    setPrecioslider(newMinPrice);
    if (onMinPriceChange) {
      onMinPriceChange(newMinPrice);
    }
    if (onSliderPriceChange) {
      onSliderPriceChange(newMinPrice);
    }
    calculatePrices();
  };

  const toggleInsured = () => setShowInsured(prevShowInsured => !prevShowInsured);
  const toggleExpand = () => setIsExpanded(prevIsExpanded => !prevIsExpanded);

  const addDependent = useCallback(() => {
    setDependents(prevDependents => [...prevDependents, { age: undefined, gender: undefined }]);
    console.log("Dependiente añadido. Dependientes actuales:", dependents);
  }, [dependents]);

  const removeDependent = useCallback((indexToRemove) => {
    setDependents(prevDependents => prevDependents.filter((_, index) => index !== indexToRemove));
    console.log("Dependiente eliminado. Dependientes actuales:", dependents);
  }, [dependents]);

  const updateDependent = useCallback((index, field, value) => {
    setDependents(prevDependents => {
      const newDependents = [...prevDependents];
      newDependents[index] = { ...newDependents[index], [field]: value || undefined };
      console.log(`Dependiente actualizado (index: ${index}). Dependientes actuales:`, newDependents);
      return newDependents;
    });
  }, []);

  const toggleSpouse = useCallback(() => {
    setSpouse(prevSpouse => prevSpouse ? null : { age: undefined, gender: undefined, income: undefined });
  }, []);

  const updateSpouse = useCallback((field, value) => {
    setSpouse(prevSpouse => ({ ...prevSpouse, [field]: value || undefined }));
  }, []);

  const handlePriceChange = useCallback((event) => {
    const newPrice = Number(event.target.value);
    console.log("Precio mínimo ajustado por el slider en FilterMenu:", newPrice);
    setMinPrice(newPrice);
    setPrecioMinimoCLP(newPrice);
    setPrecioslider(newPrice);
    if (onMinPriceChange) {
      onMinPriceChange(newPrice);
    }
    if (onSliderPriceChange) {
      onSliderPriceChange(newPrice);
    }
  }, [onMinPriceChange, onSliderPriceChange]);

  const handleSliderRelease = useCallback(() => {
    console.log("Slider soltado. Enviando solicitud con precio mínimo:", precioMinimoCLP);
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
    debounceTimerRef.current = setTimeout(() => {
      calculatePrices();
    }, 500);
  }, [precioMinimoCLP, calculatePrices]);

  const formatPrice = useCallback((price) => {
    return new Intl.NumberFormat('es-CL', { style: 'currency', currency: 'CLP' }).format(price);
  }, []);

  const displaySelectedFilters = () => {
    let selectedFilters = `<strong>Región:</strong> ${RegionSeleccionada || 'No seleccionada'}`;
    if (filters.income) selectedFilters += ` + <strong>Ingreso:</strong> <strong>$</strong> ${parseFloat((filters.income || '').replace(/\./g, '')).toLocaleString()}`;
    if (filters.edad_cotizante1) selectedFilters += ` + <strong>Edad:</strong> ${filters.edad_cotizante1}`;
    if (filters.gender) selectedFilters += ` + <strong>Sexo:</strong> ${filters.gender === 'male' ? 'Masculino' : 'Femenino'}`;
    if (spouse) selectedFilters += ` + <strong>Pareja/Cónyuge:</strong> ${spouse ? 1 : 0}`;
    selectedFilters += ` + <strong>Precio Mínimo:</strong> ${formatPrice(precioMinimoCLP)}`;
    console.log("Filtros seleccionados para mostrar:", selectedFilters);
    return selectedFilters;
  };

  return (
    <div className="w-full p-4 rounded-lg bg-white shadow-lg transition-shadow duration-300">
      <div className={`${deviceType === 'mobile' && !isExpanded ? 'max-h-20' : ''} overflow-hidden transition-all duration-300`}>
        <div className="flex flex-wrap items-center justify-start gap-2 w-full">
          <select
            value={filters.region || ''}
            onChange={(e) => handleInputChange('region', e.target.value)}
            className="flex-grow flex-shrink-0 min-w-[120px] h-[60px] p-2 border rounded-md bg-gray-100 text-black hover:bg-[#e5e7eb] transition-all duration-200"
          >
            <option value="">Seleccionar región</option>
            {regions.map((region) => (
              <option key={region.value} value={region.value}>{region.label}</option>
            ))}
          </select>
          <input
            type="text"
            value={`$ ${formatIncome(filters.income || '')}`}
            onChange={(e) => handleInputChange('income', e.target.value.replace(/\$ /, ''))}
            className="flex-grow flex-shrink-0 min-w-[150px] h-[60px] p-2 border rounded-md bg-gray-100 text-gray-600 hover:bg-[#e5e7eb] transition-all duration-200"
            placeholder="Ingreso Mensual Líquido"
          />
          <input
            type="number"
            value={filters.edad_cotizante1 || ''}
            onChange={(e) => handleInputChange('edad_cotizante1', e.target.value)}
            className="flex-grow flex-shrink-0 min-w-[80px] h-[60px] p-2 border rounded-md bg-gray-100 text-black hover:bg-[#e5e7eb] transition-all duration-200"
            placeholder="Edad"
          />
          <select
            value={filters.gender || ''}
            onChange={(e) => handleInputChange('gender', e.target.value)}
            className="flex-grow flex-shrink-0 min-w-[100px] h-[60px] p-2 border rounded-md bg-gray-100 hover:bg-[#e5e7eb] transition-all duration-200"
          >
            <option value="">Género</option>
            <option value="male">Hombre</option>
            <option value="female">Mujer</option>
          </select>
          <button
            onClick={toggleInsured}
            className="flex-grow flex-shrink-0 min-w-[150px] h-[60px] p-2 border rounded-md bg-gray-100 text-left flex items-center justify-between hover:bg-[#e5e7eb] transition-all duration-200"
          >
            <span className="flex items-center truncate">
              <User size={16} className="mr-1 flex-shrink-0" />
              <span className="truncate">Modificar asegurados</span>
            </span>
            <ChevronDown size={16} className="flex-shrink-0" />
          </button>
          <button
            onClick={handleCalculateClick}
            className="flex-grow flex-shrink-0 min-w-[150px] h-[60px] p-2 bg-[#0063b2] text-white rounded-md animated-button font-bold text-lg transition-all duration-200 hover:border-black hover:border-2"
          >
            Calcular precios
          </button>
        </div>

        <div className="mt-4 text-sm text-gray-600">
          <div dangerouslySetInnerHTML={{ __html: displaySelectedFilters() }}></div>
        </div>
      </div>

      {deviceType === 'mobile' && (
        <button
          onClick={toggleExpand}
          className="w-full mt-4 p-2 bg-[#0063b2] text-white rounded-md hover:bg-[#e5e7eb] transition-all duration-200"
        >
          {isExpanded ? 'Cerrar perfil' : 'Editar perfil'}
        </button>
      )}

      {showInsured && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div 
            ref={insuredRef}
            className="bg-white rounded-lg p-6 w-11/12 max-w-2xl max-h-[90vh] overflow-y-auto"
          >
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-xl font-semibold">Agregar asegurados</h3>
              <button onClick={toggleInsured} className="text-gray-500 hover:text-gray-700 hover:bg-[#e5e7eb] transition-all duration-200">
                <X size={24} />
              </button>
            </div>
            <div className="mb-6">
              <div className="flex justify-between items-center mb-2">
                <span className="text-lg">Pareja/Cónyuge</span>
                <input
                  type="checkbox"
                  checked={spouse !== null}
                  onChange={toggleSpouse}
                  className="w-6 h-6"
                />
              </div>
              {spouse && (
                <div className="mt-4 grid grid-cols-3 gap-4">
                  <select
                    value={spouse.gender || ''}
                    onChange={(e) => updateSpouse('gender', e.target.value)}
                    className="p-3 border rounded-md hover:bg-[#e5e7eb] transition-all duration-200"
                  >
                    <option value="">Género</option>
                    <option value="male">Hombre</option>
                    <option value="female">Mujer</option>
                  </select>
                  <input
                    type="number"
                    value={spouse.age || ''}
                    onChange={(e) => updateSpouse('age', e.target.value)}
                    className="p-3 border rounded-md hover:bg-[#e5e7eb] transition-all duration-200"
                    placeholder="Edad"
                  />
                  <input
                    type="text"
                    value={`$ ${formatIncome(spouse.income || '')}`}
                    onChange={(e) => updateSpouse('income', e.target.value.replace(/\$ /, ''))}
                    className="p-3 border rounded-md hover:bg-[#e5e7eb] transition-all duration-200"
                    placeholder="Ingreso Líquido"
                  />
                </div>
              )}
            </div>
            <div>
              <div className="flex justify-between items-center mb-4">
                <span className="text-lg">Cargas</span>
                <div className="flex items-center">
                  <button 
                    onClick={() => removeDependent(dependents.length - 1)} 
                    className="p-2 bg-gray-200 rounded-full mr-4 hover:bg-[#e5e7eb] transition-all duration-200"
                    disabled={dependents.length === 0}
                  >
                    <Minus size={20} />
                  </button>
                  <span className="text-xl font-semibold mx-4">{dependents.length}</span>
                  <button onClick={addDependent} className="p-2 bg-gray-200 rounded-full ml-4 hover:bg-[#e5e7eb] transition-all duration-200">
                    <Plus size={20} />
                  </button>
                </div>
              </div>
              {dependents.map((dependent, index) => (
                <div key={index} className="flex items-center space-x-4 mb-4">
                  <span className="text-lg">Carga {index + 1}</span>
                  <select
                    value={dependent.gender || ''}
                    onChange={(e) => updateDependent(index, 'gender', e.target.value)}
                    className="flex-1 p-3 border rounded-md hover:bg-[#e5e7eb] transition-all duration-200"
                  >
                    <option value="">Género</option>
                    <option value="male">Hombre</option>
                    <option value="female">Mujer</option>
                  </select>
                  <input
                    type="number"
                    value={dependent.age || ''}
                    onChange={(e) => updateDependent(index, 'age', e.target.value)}
                    className="flex-1 p-3 border rounded-md hover:bg-[#e5e7eb] transition-all duration-200"
                    placeholder="Edad"
                  />
                  <button 
                    onClick={() => removeDependent(index)} 
                    className="p-2 bg-red-200 rounded-full ml-4 hover:bg-[#e5e7eb] transition-all duration-200"
                  >
                    <Minus size={20} />
                  </button>
                </div>
              ))}
            </div>
            <button
              onClick={() => {
                handleCalculateClick();
                toggleInsured();
              }}
              className="w-full mt-6 p-3 bg-[#0063b2] text-white rounded-md text-lg font-semibold hover:bg-blue-700 transition duration-300"
            >
              Guardar y calcular precios
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default FilterMenu;
