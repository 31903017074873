import axios from 'axios';
axios.defaults.headers.common['Content-Type'] = 'application/json; charset=utf-8';
const API_URL = process.env.REACT_APP_API_URL || 'https://infoplan.cl:4000/api';

const planService = {
  obtenerEjecutivoActivo: async () => {
    try {
      const respuesta = await axios.get(`${API_URL}/ejecutivo-activo`);
      console.log('Valor de ejecutivoActivo obtenido:', respuesta.data.ejecutivoActivo);
      return respuesta.data.ejecutivoActivo;
    } catch (error) {
      console.error('Error al obtener ejecutivoActivo:', error.message);
      return null;
    }
  },

  getAllPlanes: async () => {
    console.log('getAllPlanes llamado');
    try {
      console.log('Solicitando todos los planes disponibles...');
      const ejecutivoActivo = await planService.obtenerEjecutivoActivo();
      const response = await axios.get(`${API_URL}/planes`);
      console.log('Planes obtenidos exitosamente:', response.data);
      console.log('Valor de ejecutivoActivo:', ejecutivoActivo);
      return response.data;
    } catch (error) {
      console.error('Error al obtener todos los planes:', error.message);
      throw new Error('No se pudieron obtener los planes. Por favor, intente m�s tarde.');
    }
  },

  filtrarPlanes: async (filtros) => {
    console.log('filtrarPlanes llamado con filtros:', filtros);
    const idSolicitud = Math.random().toString(36).substring(2, 15);
    console.log(`[ID de Solicitud: ${idSolicitud}] Procesando filtros...`);
    try {
      const ejecutivoActivo = await planService.obtenerEjecutivoActivo();

      // Manejar isapre correctamente
      let isapresArray = filtros.isapre;
      if (typeof isapresArray === 'string') {
        isapresArray = isapresArray.split(',').map(item => item.trim());
      } else if (!Array.isArray(isapresArray)) {
        isapresArray = [];
      }

      console.log(`[ID de Solicitud: ${idSolicitud}] Filtros recibidos con valor de isapre:`, isapresArray);

      // Manejar cl�nicas correctamente
      let clinicasArray = filtros.clinicas;
      if (typeof clinicasArray === 'string') {
        clinicasArray = clinicasArray.split(',').map(item => item.trim());
      } else if (!Array.isArray(clinicasArray)) {
        clinicasArray = [];
      }

      const parametros = {
        region: filtros.region || null,
        edad_cotizante1: filtros.edad_cotizante1 || null,
        edad_cotizante2: filtros.edad_cotizante2 || null,
        edades_cargas: filtros.edades_cargas || null,
        precio_minimo_clp: filtros.precio_minimo_clp || null,
        tipo_de_plan: filtros.tipo_de_plan || null,
        cobertura_hospitalaria: filtros.cobertura_hospitalaria || null,
        cobertura_ambulatoria: filtros.cobertura_ambulatoria || null,
        cobertura_hospitalaria_minima: filtros.cobertura_hospitalaria_minima || null,
        cobertura_ambulatoria_minima: filtros.cobertura_ambulatoria_minima || null,
        isapre: isapresArray.length > 0 ? isapresArray.join(',') : null,
        clinicas: clinicasArray.length > 0 ? clinicasArray.join(',') : null
      };

      console.log(`[ID de Solicitud: ${idSolicitud}] Par�metros iniciales recibidos:`, parametros);

      // Filtrar par�metros nulos, pero mantener los que son expl�citamente 0 o cadena vac�a
      const parametrosFiltrados = Object.entries(parametros).reduce((acumulador, [clave, valor]) => {
        if (valor !== null && valor !== undefined) {
          acumulador[clave] = valor;
        }
        return acumulador;
      }, {});

      console.log(`[ID de Solicitud: ${idSolicitud}] Par�metros finales enviados a la API:`, parametrosFiltrados);
      console.log('Valor de ejecutivoActivo antes de enviar a la API:', ejecutivoActivo);

      const respuesta = await axios.get(`${API_URL}/planes/filtrar`, { params: parametrosFiltrados });
      console.log(`[ID de Solicitud: ${idSolicitud}] Respuesta del backend recibida:`, respuesta.data);

      return respuesta.data;
    } catch (error) {
      console.error(`[ID de Solicitud: ${idSolicitud}] Error al filtrar planes:`, error.message);
      if (error.response) {
        console.error('Datos de respuesta:', error.response.data);
        console.error('Estado de respuesta:', error.response.status);
        if (error.response.status === 404) {
          throw new Error('No se encontraron planes que coincidan con los filtros especificados.');
        }
      }
      throw new Error('Hubo un problema al filtrar los planes. Por favor, intente de nuevo.');
    }
  },

  getPlanById: async (id) => {
    console.log(`getPlanById llamado con id: ${id}`);
    try {
      console.log(`Solicitando detalles para el plan con ID ${id}...`);
      const ejecutivoActivo = await planService.obtenerEjecutivoActivo();
      const respuesta = await axios.get(`${API_URL}/planes/${id}`);
      console.log(`Detalles para el plan con ID ${id} obtenidos exitosamente:`, respuesta.data);
      console.log('Valor de ejecutivoActivo:', ejecutivoActivo);
      return respuesta.data;
    } catch (error) {
      console.error(`Error al obtener el plan con ID ${id}:`, error.message);
      throw new Error(`No se pudo obtener el plan con ID ${id}. Por favor, intente de nuevo.`);
    }
  }
};

export default planService;