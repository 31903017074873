import React, { useState, useEffect, useCallback, useRef } from 'react';
import HealthPlanCard from './HealthPlanCard';

const PlanList = React.memo(({ isapreLogos, planes, filters, clinicaSeleccionada, region, cargas, ejecutivoActivo }) => {
  const [filteredPlanes, setFilteredPlanes] = useState([]);
  const [clinics, setClinics] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [plansPerPage, setPlansPerPage] = useState(8);
  const mainContainerRef = useRef(null);
  const [isLoading, setIsLoading] = useState(true);
  const [sortOrder, setSortOrder] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  console.log('PlanList recibió ejecutivoActivo:', ejecutivoActivo);

  useEffect(() => {
    const checkIfMobile = () => {
      setPlansPerPage(window.innerWidth <= 768 ? 20 : 8);
    };

    checkIfMobile();
    window.addEventListener('resize', checkIfMobile);

    return () => window.removeEventListener('resize', checkIfMobile);
  }, []);

  const decodeText = useCallback((text) => {
    try {
      const decoder = new TextDecoder('utf-8');
      const encodedText = new TextEncoder().encode(text);
      return decoder.decode(encodedText);
    } catch (e) {
      console.error('Error decoding text:', e);
      return text;
    }
  }, []);

  const extractClinics = useCallback((planesData) => {
    const uniqueClinics = [...new Set(planesData.flatMap(plan => 
      plan.prestadores ? plan.prestadores.split('\n').map(prestador => decodeText(prestador.trim())) : []
    ))];
    setClinics(uniqueClinics);
  }, [decodeText]);

  const calculatePagination = useCallback((planesData) => {
    setTotalPages(Math.ceil(planesData.length / plansPerPage));
  }, [plansPerPage]);

  useEffect(() => {
    if (planes.length > 0) {
      extractClinics(planes);
      calculatePagination(planes);
      setCurrentPage(1);
    }
    console.log('Planes recibidos:', planes);
    console.log('Cargas recibidas:', cargas);
  }, [planes, extractClinics, calculatePagination, cargas]);

  useEffect(() => {
    setIsLoading(true);
    let filtered = [...planes]; // Create a new array to avoid mutating the original

    console.log('Filtros aplicados:', filters);
    console.log('Cargas aplicadas:', cargas);

    if (filters.edades_cargas === '') {
      setFilteredPlanes(filtered);
    } else {
      // Aquí se eliminó la parte que filtraba por isapre.
      setFilteredPlanes(filtered);
    }

    // Aplicar búsqueda por código de plan y nombre del plan
    if (searchTerm) {
      filtered = filtered.filter(plan => 
        plan.codigo_plan.toLowerCase().includes(searchTerm.toLowerCase()) ||
        plan.plan.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Aplicar ordenamiento
    if (sortOrder === 'price-asc') {
      filtered.sort((a, b) => a.precio_total_clp - b.precio_total_clp);
    } else if (sortOrder === 'price-desc') {
      filtered.sort((a, b) => b.precio_total_clp - a.precio_total_clp);
    } else if (sortOrder === 'score-desc') {
      filtered.sort((a, b) => b.nota - a.nota);
    }

    setFilteredPlanes(filtered);
    console.log('Planes filtrados y ordenados:', filtered);

    calculatePagination(filtered);
    setCurrentPage(1);
    setIsLoading(false);
  }, [filters, planes, calculatePagination, cargas, sortOrder, searchTerm]);

  const handlePageChange = useCallback((newPage) => {
    setCurrentPage(newPage);
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []);

  const renderPaginationButtons = useCallback(() => {
    const buttons = [];
    const maxVisibleButtons = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxVisibleButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxVisibleButtons - 1);

    if (endPage - startPage + 1 < maxVisibleButtons) {
      startPage = Math.max(1, endPage - maxVisibleButtons + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      buttons.push(
        <button
          key={i}
          onClick={() => handlePageChange(i)}
          className={`px-3 py-1 rounded ${
            currentPage === i ? 'bg-blue-500 text-white' : 'bg-gray-200'
          }`}
        >
          {i}
        </button>
      );
    }

    return buttons;
  }, [currentPage, totalPages, handlePageChange]);

  const handleSortChange = (e) => {
    const newSortOrder = e.target.value;
    console.log('Nuevo orden de clasificación:', newSortOrder);
    setSortOrder(newSortOrder);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  return (
    <>
      <div className="flex flex-row justify-end items-center space-x-2 px-4 sm:px-0" style={{ marginTop: '-10px', marginLeft: '-5px', marginBottom: '20px' }}>
        <div className="w-1/2 sm:w-64">
          <div className="relative">
            <input
              type="text"
              id="search"
              className="w-full pl-10 pr-3 py-2 text-sm rounded-md border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 h-10 sm:h-12"
              placeholder="Código de plan..."
              value={searchTerm}
              onChange={handleSearchChange}
            />
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <svg className="h-4 w-4 sm:h-5 sm:w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor">
                <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clipRule="evenodd" />
              </svg>
            </div>
          </div>
        </div>
        <div className="w-1/2 sm:w-64">
          <div className="relative">
            <select
              id="sort"
              className="w-full pl-3 pr-10 py-2 text-sm border border-gray-300 focus:outline-none focus:ring-1 focus:ring-blue-500 rounded-md appearance-none h-10 sm:h-12"
              value={sortOrder}
              onChange={handleSortChange}
            >
              <option value="">Seleccionar...</option>
              <option value="price-asc">Precio menor a mayor</option>
              <option value="price-desc">Precio mayor a menor</option>
              <option value="score-desc">Mejor puntaje</option>
            </select>
            <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-2 text-gray-700">
              <svg className="fill-current h-4 w-4" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                <path d="M9.293 12.95l.707.707L15.657 8l-1.414-1.414L10 10.828 5.757 6.586 4.343 8z"/>
              </svg>
            </div>
          </div>
        </div>
      </div>

      <div 
        ref={mainContainerRef} 
        className="container mx-auto px-4" 
        style={{ 
          height: 'auto',
          scrollBehavior: 'auto',
          WebkitOverflowScrolling: 'touch'
        }}
      >
        <div className="grid grid-cols-1 gap-4">
          {isLoading ? (
            [...Array(plansPerPage)].map((_, index) => (
              <div key={index} className="bg-white rounded-lg shadow-md p-4 w-full border border-gray-200 animate-pulse">
                <div className="h-8 bg-gray-200 rounded w-1/4 mb-4"></div>
                <div className="h-20 bg-gray-200 rounded mb-4"></div>
                <div className="h-8 bg-gray-200 rounded w-1/2"></div>
              </div>
            ))
          ) : (
            filteredPlanes
              .slice((currentPage - 1) * plansPerPage, currentPage * plansPerPage)
              .map((plan) => {
                console.log('Renderizando plan:', plan.id, 'con cargas:', cargas, 'y ejecutivoActivo:', ejecutivoActivo);
                return (
                  <HealthPlanCard
                    key={plan.id}
                    plan={{
                      id: plan.id,
                      type: plan.tipo_de_plan,
                      isapre: {
                        name: decodeText(plan.isapre),
                        logo: isapreLogos[plan.isapre]
                      },
                      name: decodeText(plan.plan),
                      score: plan.nota ? plan.nota.toString() : '0',
                      hospitalCoverage: decodeText(plan.cobertura_hospitalaria),
                      ambulatoryCoverage: decodeText(plan.cobertura_ambulatoria),
                      urgencyCoverage: decodeText(plan.cobertura_urgencia),
                      freeChoiceHospital: plan.cobertura_libre_eleccion_hospitalaria,
                      freeChoiceAmbulatory: plan.cobertura_libre_eleccion_ambulatoria,
                      annualLimit: plan.tope_anual,
                      price: plan.precio_total_clp,
                      prestadores: decodeText(plan.prestadores),
                      code: decodeText(plan.codigo_plan),
                      region: decodeText(plan.region)
                    }}
                    selectedClinic={clinicaSeleccionada}
                    region={decodeText(plan.region)}
                    edad_cotizante1={filters.edad_cotizante1}
                    edad_cotizante2={filters.edad_cotizante2}
                    cargas={cargas}
                    precio_minimo_clp={filters.precio_minimo_clp}
                    ejecutivoActivo={ejecutivoActivo}
                  />
                );
              })
          )}
        </div>
      </div>
      
      <div className="flex justify-center items-center space-x-2 mt-8 mb-4">
        <button
          onClick={() => handlePageChange(currentPage - 1)}
          disabled={currentPage === 1}
          className="px-3 py-1 bg-blue-500 text-white rounded disabled:bg-gray-300"
        >
          Anterior
        </button>
        {renderPaginationButtons()}
        <button
          onClick={() => handlePageChange(currentPage + 1)}
          disabled={currentPage === totalPages}
          className="px-3 py-1 bg-blue-500 text-white rounded disabled:bg-gray-300"
        >
          Siguiente
        </button>
      </div>

      {/* Divider transparente */}
      <div className="w-full h-12 bg-transparent"></div>
    </>
  );
});

export default PlanList;