import React, { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import { AppProvider } from './AppContext';
import FilterMenu from './FilterMenu';
import AdvancedFilters from './AdvancedFilters';
import PlanList from './components/PlanList';
import BanmedicaLogo from './assets/Banmedica.webp';
import ColmenaLogo from './assets/Colmena.webp';
import EsencialLogo from './assets/Esencial.webp';
import NuevaMasVidaLogo from './assets/NuevaMasvida.webp';
import VidatresLogo from './assets/Vidatres.webp';
import BannerImage from './assets/banner.webp';
import InfoPlanLogo from './assets/logoinfo.webp';
import planService from './services/planService';
import './App.css';
import ExecutiveDashboard from './components/ExecutiveDashboard';
import DashboardAdmin from './components/DashboardAdmin';

const App = () => {
  const [esMovil, setEsMovil] = useState(window.innerWidth <= 1100);
  const [filtros, setFiltros] = useState({});
  const [planes, setPlanes] = useState([]);
  const [estaCargando, setEstaCargando] = useState(false);
  const [error, setError] = useState(null);
  const [precioMinimo, setPrecioMinimo] = useState(0);
  const [precioSlider, setPrecioSlider] = useState(0);
  const referenciaFiltrosPrevios = useRef(filtros);
  const cargaInicialRealizada = useRef(false);
  const [clinicaSeleccionada, setClinicaSeleccionada] = useState('');
  const [region, setRegion] = useState('');
  const [coberturaHospitalariaMinima, setCoberturaHospitalariaMinima] = useState('60');
  const [coberturaAmbulatoriaMinima, setCoberturaAmbulatoriaMinima] = useState('60');
  const [mostrarDashboard, setMostrarDashboard] = useState(false);
  const [mostrarDashboardAdmin, setMostrarDashboardAdmin] = useState(false);
  const [ejecutivoActivo, setEjecutivoActivo] = useState('');

  const logotiposIsapre = {
    Banmedica: BanmedicaLogo,
    Colmena: ColmenaLogo,
    Esencial: EsencialLogo,
    'Nueva Masvida': NuevaMasVidaLogo,
    Vidatres: VidatresLogo,
  };

  useEffect(() => {
    const manejarCambioTamanio = () => {
      setEsMovil(window.innerWidth <= 1100);
    };
    window.addEventListener('resize', manejarCambioTamanio);
    return () => {
      window.removeEventListener('resize', manejarCambioTamanio);
    };
  }, []);

  useEffect(() => {
    const obtenerEjecutivoActivo = async () => {
      try {
        const ejecutivo = await planService.obtenerEjecutivoActivo();
        console.log("Ejecutivo activo obtenido:", ejecutivo);
        setEjecutivoActivo(ejecutivo);
      } catch (error) {
        console.error("Error al obtener el ejecutivo activo:", error);
      }
    };

    obtenerEjecutivoActivo();
  }, []);

  const obtenerPlanes = useCallback(async (filtrosActuales) => {
    console.log("Iniciando obtenerPlanes con filtros:", filtrosActuales);

    if (estaCargando || JSON.stringify(filtrosActuales) === JSON.stringify(referenciaFiltrosPrevios.current)) {
      console.log("Evitando b�squeda duplicada o mientras se est� cargando.");
      return;
    }

    setEstaCargando(true);
    setError(null);

    const filtrosSQL = {
      edad_cotizante1: filtrosActuales.edad_cotizante1 || null,
      edad_cotizante2: filtrosActuales.edad_cotizante2 || null,
      edades_cargas: filtrosActuales.edades_cargas || null,
      sexo: filtrosActuales.sexo || null,
      clinicas: filtrosActuales.clinicas || [],
      region: filtrosActuales.region || null,
      tipo_de_plan: filtrosActuales.tipo_de_plan || null,
      isapre: filtrosActuales.isapre || null,
      precio_minimo_clp: filtrosActuales.precio_minimo_clp || null,
      cargas: filtrosActuales.edades_cargas ? filtrosActuales.edades_cargas.split(',').length : 0,
      cobertura_hospitalaria_minima: filtrosActuales.cobertura_hospitalaria_minima || coberturaHospitalariaMinima,
      cobertura_ambulatoria_minima: filtrosActuales.cobertura_ambulatoria_minima || coberturaAmbulatoriaMinima
    };

    console.log("Filtros preparados para SQL:", filtrosSQL);

    try {
      const datos = await planService.filtrarPlanes(filtrosSQL);
      console.log("Datos recibidos de filtrarPlanes:", datos);
      setPlanes(datos);
      referenciaFiltrosPrevios.current = filtrosActuales;
    } catch (error) {
      console.error('Error al obtener los planes:', error);
      setError('Hubo un error al obtener los planes. Por favor, intente de nuevo.');
    } finally {
      setEstaCargando(false);
    }
  }, [estaCargando, coberturaHospitalariaMinima, coberturaAmbulatoriaMinima]);

  const obtenerPlanesConRetraso = useMemo(() => {
    const funcionConRetraso = (filtrosActuales) => {
      console.log("Aplicando retraso a obtenerPlanes");
      const temporizador = setTimeout(() => {
        obtenerPlanes(filtrosActuales);
      }, 300);
      return () => clearTimeout(temporizador);
    };
    return funcionConRetraso;
  }, [obtenerPlanes]);

  useEffect(() => {
    if (!cargaInicialRealizada.current) {
      console.log("Realizando carga inicial de planes");
      const filtrosIniciales = { region: '' };
      obtenerPlanes(filtrosIniciales);
      cargaInicialRealizada.current = true;
    } else if (Object.keys(filtros).length > 0) {
      console.log("Filtros actualizados, llamando a obtenerPlanesConRetraso");
      obtenerPlanesConRetraso(filtros);
    }
  }, [filtros, obtenerPlanesConRetraso, obtenerPlanes]);

  const manejarCambioFiltro = useCallback((nuevosFiltros) => {
    console.log("manejarCambioFiltro recibe los siguientes filtros:", nuevosFiltros);
    
    setFiltros((filtrosPrevios) => {
      const filtrosActualizados = { ...filtrosPrevios };

      Object.entries(nuevosFiltros).forEach(([clave, valor]) => {
        if (clave === 'clinicas') {
          filtrosActualizados.clinicas = valor;
          setClinicaSeleccionada(valor);
        } else if (clave === 'edad_cotizante1' || clave === 'edad_cotizante2') {
          filtrosActualizados[clave] = valor === '' ? null : valor;
        } else if (clave === 'edades_cargas') {
          filtrosActualizados[clave] = valor === '' ? null : valor;
        } else if (clave === 'tipo_de_plan') {
          filtrosActualizados.tipo_de_plan = valor;
        } else if (clave === 'region') {
          filtrosActualizados.region = valor;
          setRegion(valor);
        } else if (clave === 'cobertura_hospitalaria_minima') {
          filtrosActualizados.cobertura_hospitalaria_minima = valor;
          setCoberturaHospitalariaMinima(valor);
        } else if (clave === 'cobertura_ambulatoria_minima') {
          filtrosActualizados.cobertura_ambulatoria_minima = valor;
          setCoberturaAmbulatoriaMinima(valor);
        } else if (valor === undefined || valor === '') {
          console.warn(`Eliminando filtro indefinido o vac�o: ${clave}`);
          delete filtrosActualizados[clave];
        } else {
          filtrosActualizados[clave] = valor;
        }
      });

      console.log("manejarCambioFiltro actualiza los filtros a:", filtrosActualizados);
      return filtrosActualizados;
    });
  }, []);

  const manejarCambioPrecioMinimo = useCallback((nuevoPrecioMinimo) => {
    console.log("Cambio en precio m�nimo:", nuevoPrecioMinimo);
    setPrecioMinimo(nuevoPrecioMinimo);
  }, []);

  const manejarCambioPrecioSlider = useCallback((nuevoPrecioSlider) => {
    console.log("Cambio en slider de precio:", nuevoPrecioSlider);
    setPrecioSlider(nuevoPrecioSlider);
  }, []);

  const cerrarDashboard = useCallback(() => {
    setMostrarDashboard(false);
  }, []);

  const cerrarDashboardAdmin = useCallback(() => {
    setMostrarDashboardAdmin(false);
  }, []);

  // Verificaci�n de ejecutivoActivo
  console.log("ejecutivoActivo:", ejecutivoActivo);

  if (mostrarDashboard) {
    return <ExecutiveDashboard onClose={cerrarDashboard} />;
  }

  if (mostrarDashboardAdmin) {
    return <DashboardAdmin onClose={cerrarDashboardAdmin} />;
  }

  console.log("Enviando ejecutivoActivo a PlanList:", ejecutivoActivo);

  return (
    <AppProvider>
      <div className={`min-h-screen relative ${esMovil ? 'bg-white' : 'bg-[#f5f5f5]'}`}>
        <div
          className="absolute top-0 left-0 w-full z-10"
          style={{
            backgroundImage: `url(${BannerImage})`,
            backgroundSize: 'cover',
            backgroundPosition: 'top center',
            height: '220px',
          }}
        ></div>
        <header
          className={`flex items-center py-2 z-20 ${esMovil ? 'flex-col' : 'flex-row justify-start'}`}
          style={{
            marginLeft: esMovil ? '10px' : '50px',
            position: 'relative',
            backgroundColor: 'transparent',
          }}
        >
          <img
            src={InfoPlanLogo}
            alt="InfoPlan Logo"
            style={{
              width: esMovil ? '160px' : '180px',
              height: 'auto',
              display: 'block',
              marginTop: esMovil ? '5px' : '25px',
            }}
          />

          <div
            className={`${esMovil ? 'text-center mt-2' : 'ml-4 text-left'}`}
            style={{
              width: esMovil ? '100%' : 'auto',
              marginLeft: esMovil ? '0' : '50px',
              marginTop: esMovil ? '0' : '20px'
            }}
          >
            <h1 className={`text-4xl lg:text-6xl font-bold ${esMovil ? 'text-[#004677]' : 'text-[#004677]'}`}>
              Planes de Isapre
            </h1>
            <p className={`text-lg lg:text-xl font-bold ${esMovil ? 'text-[#0063af]' : 'text-[#0063af]'} mb-4 mt-2`}>
              Compara y Cotiza todos los Planes de Isapre Online
            </p>
          </div>
        </header>

        <main className={`relative z-20 mx-auto px-4 sm:px-6 lg:px-8 ${esMovil ? 'py-2' : 'py-6'} ${esMovil ? 'w-full' : 'max-w-[85%]'}`}>
          <div className={`mb-8 w-full ${esMovil ? 'mt-4' : '-mt-8'} bg-white rounded-lg shadow-md overflow-hidden`}>
            <FilterMenu 
              onFilterChange={manejarCambioFiltro} 
              onMinPriceChange={manejarCambioPrecioMinimo}
              onPrecioSliderChange={manejarCambioPrecioSlider} 
              isMobile={esMovil} 
            />
          </div>

          <div className={`${esMovil ? '' : 'bg-white rounded-lg shadow-md p-6'}`}>
            <div className={`flex ${esMovil ? 'flex-col' : 'flex-row'} gap-8`}>
              <div className="lg:w-[24%]">
                <AdvancedFilters 
                  onFilterChange={manejarCambioFiltro} 
                  precioMinimoFilterMenu={precioMinimo}
                  precioslider={precioSlider}
                  initialCoberturaHospitalaria={coberturaHospitalariaMinima}
                  initialCoberturaAmbulatoria={coberturaAmbulatoriaMinima}
                  isMobile={esMovil}
                />
              </div>

              <div className={esMovil ? 'w-full' : 'lg:w-[76%]'}>
                {estaCargando ? (
                  <div className="flex justify-center items-center h-64">
                    <p className="text-2xl font-bold text-[#0063af] animate-pulse">
                      Cargando planes...
                    </p>
                  </div>
                ) : error ? (
                  <div>{error}</div>
                ) : planes.length === 0 ? (
                  <div className="flex justify-center items-center h-64">
                    <p className="text-2xl font-bold text-[#004677]">
                      No se encontraron planes. Por favor, ajuste los filtros.
                    </p>
                  </div>
                ) : (
                  <PlanList 
                    isapreLogos={logotiposIsapre} 
                    planes={planes}
                    filters={filtros}
                    clinicaSeleccionada={clinicaSeleccionada}
                    region={region}
                    cargas={filtros.edades_cargas}
                    ejecutivoActivo={ejecutivoActivo}  // Aqu� se env�a la variable
                  />
                )}
              </div>
            </div>
          </div>

         <div className="mt-8 flex justify-center space-x-4">
    <button
    onClick={() => {
      setMostrarDashboard(true);
    }}
    style={{
      padding: '8px 16px', // Tama�o m�s peque�o
      backgroundColor: '#fff', // Fondo blanco
      color: '#2563eb', // Texto azul
      border: '2px solid #2563eb', // Borde azul
      borderRadius: '5px',
      fontSize: '14px', // Tama�o de fuente m�s peque�o
      fontWeight: 'bold',
      cursor: 'pointer',
      transition: 'background-color 0.3s, color 0.3s',
    }}
    onMouseOver={(e) => {
      e.target.style.backgroundColor = '#2563eb';
      e.target.style.color = '#fff';
    }}
    onMouseOut={(e) => {
      e.target.style.backgroundColor = '#fff';
      e.target.style.color = '#2563eb';
    }}
  >
    Portal Ejecutivos
  </button>

  <button
    onClick={() => {
      setMostrarDashboardAdmin(true);
    }}
    style={{
      padding: '8px 16px', // Tama�o m�s peque�o
      backgroundColor: '#fff', // Fondo blanco
      color: '#2563eb', // Texto azul
      border: '2px solid #2563eb', // Borde azul
      borderRadius: '5px',
      fontSize: '14px', // Tama�o de fuente m�s peque�o
      fontWeight: 'bold',
      cursor: 'pointer',
      transition: 'background-color 0.3s, color 0.3s',
    }}
    onMouseOver={(e) => {
      e.target.style.backgroundColor = '#2563eb';
      e.target.style.color = '#fff';
    }}
    onMouseOut={(e) => {
      e.target.style.backgroundColor = '#fff';
      e.target.style.color = '#2563eb';
    }}
  >
    Admin
  </button>

</div>

        </main>
      </div>
    </AppProvider>
  );
};

export default App;
